import React, { useRef, useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePurchase } from "../contexts/PurchaseContext";
import { Link } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { getCountryService } from "../services/getCountryService";
import { DayPicker } from "react-day-picker";
import Typewriter from "../component/text/Typewriter";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { getGallery } from "../services/galleryService";
import { getWhyTolltake } from "../services/whyTolltakeService";

import References from "../component/homepages/References";

import { tr } from "date-fns/locale";
import {
  addDays,
  format,
  startOfDay,
  isWithinInterval,
  isBefore,
  isFriday,
  isWeekend,
  set,
} from "date-fns";
import axios from "axios";
import WhyTolltake from "../component/homepages/WhyTolltake";
import Comment from "../component/homepages/Comment";
import Faq from "../component/homepages/Faq";
import WhyVn from "../component/homepages/WhyVn";
import { Helmet } from "react-helmet";
import SEO from "../component/content/Seo";

function ToggleStateFnc({
  handleSelectionChange = () => {},
  purchaseData,
  setPurchaseData,
}) {
  const { t } = useTranslation();

  const [toggleState, setToggleState] = useState(0); // Index'i takip etmek için başlangıçta 0 yapıyoruz
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVignette, setSelectedVignette] = useState(null);
  const [selectTime, setSelectTime] = useState(null);
  const [vignettes, setVignettes] = useState([]);
  const [otherCountry, setotherCountry] = useState(false);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const bringCountryToFront = (index) => {
    const updatedCountries = [...countries];
    const updatedOtherCountries = [...otherCountries];
    const countryToMove = updatedOtherCountries.splice(index, 1)[0];

    updatedCountries.unshift(countryToMove);

    if (updatedCountries.length > (isMobile ? 2 : 4)) {
      const countryToRemove = updatedCountries.pop();
      updatedOtherCountries.push(countryToRemove);
    }


    setCountries(updatedCountries);
    setOtherCountries(updatedOtherCountries);
    setToggleState(0); // Yeni eklenen ülkenin indeksini ayarlıyoruz
  };

  const toggleTab = (index) => {
    if (index >= countries.length) {
      bringCountryToFront(index - countries.length);
    } else {
      setToggleState(index);
    }
  };

  const [toggleMobilTab, setToggleMobilTab] = useState(false);

  const toggleMtab = () => {
    setToggleMobilTab(!toggleMobilTab);
  };

  const [otherCountries, setOtherCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getCountryService(1);
        const mainCountries = isMobile ? apiList.Data.slice(0, 2) : apiList.Data; // Mobilde ilk iki ülke, diğerlerinde tüm ülkeler
        const otherCountries = isMobile ? apiList.Data.slice(2) : []; // Mobilde geri kalan ülkeler, diğerlerinde boş
        setCountries(mainCountries);
        setOtherCountries(otherCountries);
      } catch (error) {
        console.error("API error:", error);
      }
    };
    loadData();
  }, [isMobile]);

  useEffect(() => {
    if (countries[toggleState]) {
      axios
        .get(`${BASE_URL}/v1/VehicleType/GetAllTypeVignette`, {
          params: {
            LanguageId: 1,
            CountryId: countries[toggleState].Id,
          },
        })
        .then((response) => {
          if (response.data.Success) {
            setVehicles(response.data.Data);

            if (response.data.Data[0]) {
              setSelectedVehicle(response.data.Data[0]);
              handleSelectionChange("country", countries[toggleState].Id);

              handleSelectionChange("duration", null);
              handleSelectionChange("startDate", null);
            } else {
              setVehicles([]);
              setSelectedVehicle(null);
              setVignettes([]);
              handleSelectionChange("startDate", null);
            }
          }
        })
        .catch((error) => {
          console.error("Failed to fetch vehicles", error);
          setVehicles([]);
          setSelectedVehicle(null);
          setVignettes([]);
        });
    }
  }, [toggleState, countries]);

  useEffect(() => {
    if (toggleState < countries.length) {
      let countrySelected = countries[toggleState];

      if (countrySelected) {
        handleSelectionChange("countryName", countrySelected.Name);
        handleSelectionChange("countryImg", countrySelected.ProductCategoryImg);
      }

      handleSelectionChange("vehicleType", 1);
      handleSelectionChange("startDate", null);

      if (selectedVehicle) {
        handleSelectionChange("vehicleType", selectedVehicle.Id);
        handleSelectionChange("vehicleTypeName", selectedVehicle.Name);
        handleSelectionChange("vehicleTypeImg", selectedVehicle.Icon);
        setVignettes(selectedVehicle.Vignettes);

        if (selectedVehicle.Vignettes && selectedVehicle.Vignettes.length > 0) {
          setSelectedVignette(selectedVehicle.Vignettes[0]);

          handleSelectionChange(
            "duration",
            selectedVehicle.Vignettes[0].duration
          );
          handleSelectionChange(
            "durationName",
            selectedVehicle.Vignettes[0].Name
          );

          handleSelectionChange(
            "totalPrice",
            selectedVehicle.Vignettes[0].Price
          );
        } else {
          setSelectedVignette(null);
        }
      } else {
        setSelectedVignette(null);
      }
    }
  }, [selectedVehicle, toggleState, countries]);

  useEffect(() => {
    handleSelectionChange("startDate", null);

    if (selectedVignette) {
      handleSelectionChange("duration", selectedVignette.Duration);
      handleSelectionChange("durationName", selectedVignette.Name);

      handleSelectionChange("totalPrice", selectedVignette.Price);
      handleSelectionChange("CurrencyName", selectedVignette.CurrencyName);
      handleSelectionChange("CurrencyId", selectedVignette.CurrencyId);
      handleSelectionChange("vignette", selectedVignette.Id);
    }
  }, [selectedVignette]);

  const otherhandleClik = () => {
    setotherCountry(!otherCountry);
  };

  return (
    <div className="bg-home">
      <div className="Country-box relative">
        <div className="block-tabs flex bg-color2 rounded-t-lg relative">
          {countries.map((country, index) => (
            <div
              key={country.Id}
              className={`tabs lg: ${toggleState === index ? "block" : ""}`}
              onClick={() => {
                toggleTab(index);
                setotherCountry(false);

                handleSelectionChange("countryName", country.Name);
                handleSelectionChange("countryImg", country.ProductCategoryImg);
              }}
            >
              <img src={country.ProductCategoryImg} />
              {country.Name}
            </div>
          ))}

          <div className="tabs h-other" onClick={() => otherhandleClik()}>
            {t("digerleri")}
          </div>
        </div>

        {otherCountry && (
          <div className="sub-country">
            {otherCountries.map((country, index) => (
              <div
                key={country.Id}
                className={`tabs lg: ${
                  toggleState === countries.length + index ? "block" : ""
                }`}
                onClick={() => {
                  toggleTab(countries.length + index);
                  setotherCountry(false);
                  handleSelectionChange("countryName", country.Name);
                  handleSelectionChange(
                    "countryImg",
                    country.ProductCategoryImg
                  );
                }}
              >
                <img src={country.ProductCategoryImg} />
                {country.Name}
              </div>
            ))}
          </div>
        )}

        <div className="bg-color3 px-6 py-6">
          <div className="content-tabs block">
            <div className="flex gap-2">
              <div className="vehicle-cat lg:w-1/3 w-1/2">
                <ListSelect
                  vehicles={vehicles}
                  setSelectedVehicle={setSelectedVehicle}
                  selectedVehicle={selectedVehicle}
                  toggleState={toggleState}
                />
              </div>
              <div className="time-price lg:w-1/3 w-1/2">
                <ListDate
                  setSelectedVignette={setSelectedVignette}
                  selectedVignette={selectedVignette}
                  vignettes={vignettes}
                />
              </div>
              <div className="date-start lg:inline-block hidden lg:w-1/3">
                <StartDate
                  handleSelectionChange={handleSelectionChange}
                  purchaseData={purchaseData}
                  setPurchaseData={setPurchaseData}
                  setSelectTime={setSelectTime}
                  selectTime={selectTime}
                />
              </div>
            </div>
            <div className="date-start lg:hidden inline-block w-full mt-4">
              <StartDate
                handleSelectionChange={handleSelectionChange}
                purchaseData={purchaseData}
                setPurchaseData={setPurchaseData}
                setSelectTime={setSelectTime}
                selectTime={selectTime}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function classNames(...classes) {
  return classes.filter(String).join(" ");
}

function ListSelect({
  vehicles,
  toggleState,
  setSelectedVehicle,
  selectedVehicle,
}) {
  const [vehicle, setVehicle] = useState(vehicles || []);
  const { t } = useTranslation();

  return (
    <div>
      <Listbox value={selectedVehicle} onChange={setSelectedVehicle}>
        {({ open }) => (
          <>
            <Listbox.Label className="pl-3 text-left block font-medium text-psm text-green">
              Taşıt Türü
            </Listbox.Label>
            <div className="relative mt-1 mx-w-home">
              <Listbox.Button className="relative w-full cursor-default rounded-2xl bg-white px-5 py-3 text-left text-gray-900 border border-color4">
                <span className="flex items-center">
                  <span className="block truncate">
                    {selectedVehicle ? selectedVehicle.Name : "Veri Bulunamadı"}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Listbox.Options className="hov-a absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {vehicles &&
                    vehicles.map((vehicle) => (
                      <Listbox.Option
                        key={vehicle.Id}
                        className={({ active }) =>
                          classNames(
                            active ? "" : "text-gray-900",
                            "cursor-default select-none py-2 pr-9"
                          )
                        }
                        value={vehicle}
                      >
                        {({ selectedVehicle, active }) => (
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selectedVehicle
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {vehicle.Name}
                            </span>
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

function ListDate({
  vignettes,
  setSelectedVignette,
  selectedVignette,
  resetStartDate,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Listbox value={selectedVignette} onChange={setSelectedVignette}>
        {({ open }) => (
          <>
            <Listbox.Label className="pl-3 text-left block font-medium text-psm text-green">
                {t("gecerlilik_suresi")}

                
            </Listbox.Label>
            <div className="relative mt-1 mx-w-home">
              <Listbox.Button className="relative w-full cursor-default rounded-2xl bg-white px-5 py-3 text-left text-gray-900 border border-color4">
                <span className="flex items-center">
                  <span className="block truncate">
                    {selectedVignette
                      ? `${selectedVignette.Name} ${selectedVignette.DiscountPrice} ${selectedVignette.CurrencyName}`
                      : t("secim_yapiniz")}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <React.Fragment>
                    {vignettes &&
                      vignettes.map((vig) => (
                        <Listbox.Option
                          key={vig.Id}
                          value={vig}
                          className={({ active }) =>
                            classNames(
                              active ? "" : "text-gray-900",
                              "relative cursor-default select-none py-2 pr-9"
                            )
                          }
                        >
                          {({ selectedVignette, active }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selectedVignette
                                      ? "font-semibold"
                                      : "font-normal",
                                    "ml-3 block truncate"
                                  )}
                                >
                                  {vig.Name}{" "}
                                  <strong>
                                    {vig.DiscountPrice} {vig.CurrencyName}
                                  </strong>
                                </span>
                              </div>

                              {selectedVignette ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                  )}
                                ></span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                  </React.Fragment>
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

function StartDate({
  setSelectTime,
  selectTime,
  setPurchaseData,
  purchaseData,
  handleSelectionChange,
}) {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedRange, setSelectedRange] = useState([]);
  const [toggleDateTab, setToggleDateTab] = useState(false);

  useEffect(() => {
    if (selectedDay !== null) {
      const range = [];
      for (let i = 0; i <= purchaseData.duration; i++) {
        range.push(addDays(selectedDay, i));
      }
      setSelectedRange(range);

      handleSelectionChange(
        "startDate",
        format(selectedDay, "PPP", { locale: tr }) + " " + "00:00"
      );
      handleSelectionChange(
        "finishDate",
        format(addDays(selectedDay, purchaseData.duration), "PPP", {
          locale: tr,
        }) +
          " " +
          "23:59"
      );
      setToggleDateTab(false);
    }
  }, [selectedDay]);

  useEffect(() => {
    if (purchaseData.startDate == null) {
      const range = [];
      setSelectedDay(null);
      setSelectedRange(range);

      handleSelectionChange("startDate", null);
      handleSelectionChange("finishDate", null);
      setToggleDateTab(false);
    }
  }, [
    purchaseData.duration,
    purchaseData.startDate,
    purchaseData.country,
    purchaseData.vehicleType,
    purchaseData.countryName,
    purchaseData.CurrencyName,
    purchaseData.vignette,
  ]);

  function isFridayControl(date) {
    
    if (purchaseData.duration == 0) {
      return false;
    }
    else if (purchaseData.duration == 2) {
      return !isFriday(date);
    }
    else {
      return false;
    }

    return false;
  }

  const modifiers = {
    disabled: (date) =>
      isFridayControl(date) ||
      isBefore(date, startOfDay(new Date())) ||
      isWithinInterval(date, {
        start: selectedRange[0],
        end: selectedRange[selectedRange.length - 1],
      }),
    selectedRange: (date) =>
      isWithinInterval(date, {
        start: selectedRange[0],
        end: selectedRange[selectedRange.length - 1],
      }),
  };

  const modifiersStyles = {
    selectedRange: {
      color: "#fff",
      backgroundColor: "#F95D1F",
    },
    disabled: {
      pointerEvents: "none",
    },
  };

  let footer = <p>Please pick the first day.</p>;
  if (selectedDay) {
    footer = (
      <p>
        Range: {format(selectedDay, "PPP")}–
        {format(addDays(selectedDay, 6), "PPP")}
      </p>
    );
  }
  const handleDayClickAct = (day, { disabled }) => {
    if (!disabled) {
      setSelectedDay(day);
    }
  };

  const handleDayClick = (date) => {};

  const isWeekendDay = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const getDisabledDays = () => {
    let days = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    for (let day = 1; day <= 31; day++) {
      let testDate = new Date(currentYear, currentMonth, day);
      if (testDate.getMonth() !== currentMonth) {
        break;
      }
      if (!isWeekendDay(testDate)) {
        days.push(testDate);
      }
    }
    return days;
  };

  const disabledDays = getDisabledDays();

  const toggleDtab = () => {
    setToggleDateTab(!toggleDateTab);
  };
  const { t } = useTranslation();

  return (
    <div>
      <Listbox value={selectTime} onChange={setSelectTime}>
        {({ open }) => (
          <>
            <Listbox.Label className="pl-3 text-left block font-medium text-psm text-green">
            {t("baslangic_tarihi")}
            </Listbox.Label>
            <div className=" mt-1">
              <Listbox.Button
                onClick={() => toggleDtab(true)}
                className="relative w-full cursor-default rounded-2xl bg-white px-5 py-3 text-left text-gray-900 border border-color4"
              >
                <span className="flex items-center">
                  <span className="block truncate">{t("tarih_sec")}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <div
                className={`date-tolltake absolute  ${
                  toggleDateTab === false ? "hidden" : ""
                }`}
              >
                <div className="date-tolltake">
                  <DayPicker
                    mode="single"
                    locale={tr}
                    selected={selectedDay}
                    onSelect={handleDayClickAct}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                    disabled={modifiers.disabled}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}

const Home = () => {
  const [purchaseData, setPurchaseData] = useState([
    {
      country: "",
      countryName: "Bulgaristan",
      countryImg: "",
      vehicleType: 1,
      vehicleTypeImg: '',
      vehicleTypeName: '',
      duration: 1,
      durationName: '',
      startDate: "",
      finishDate: "",
      lisenceCountry: 1,
      lisenceCountryName: "",
      lisenceCountryImg: "",
      lisencePlate: "",
      totalPrice: 0,
      CurrencyId: "",
      vignette: "",
      CurrencyName: "",
      discountPrice: 0,
      couponCode: "",
    },
  ]);
  const { t } = useTranslation();

  const history = useHistory();

  const handleSelectionChange = (field, value) => {
    setPurchaseData((prev) => ({ ...prev, [field]: value }));
  };

  const handlePurchase = () => {
    history.push("/buy", { state: purchaseData});
    setPurchaseData({});
  };

  return (

    <div>
          <SEO
    title="TollTake - Güvenli ve Kolay Dijital Vinyet"
    description="TollTake, Türkiye’den Avrupa’ya ve Avrupa’dan Türkiye’ye seyahat edenler için hızlı ve güvenli vinyet çözümleri sunar. Kolay kayıt, kullanıcı dostu arayüz ve 7/24 müşteri hizmetleri ile huzurlu yolculuk deneyimi sağlayın. Şimdi keşfedin!"
    keywords="viynet,vignette,e vinyet, dijital vinyet,tolltake"
    author="TollTake"
    image="https://www.tolltake.com/assets/img/tolltake-logo.svg"
    url="https://www.tolltake.com"
  />
      <div className="hero relative lg:mt-[0]  mt-[-96px]   ">
        <div className="lg:container container-full mx-auto">
          <div className="flex ">
            <div className=" lg:w-3/5 w-full">
              <div className="h-full items-center bg-hero-s  block lg:pl-14 lg:pr-24  px-2 pt-16 pb-3   xl:rounded-l-3xl ">
                <div className="text-center lg:text-left">
                  <Typewriter />
                  <p className="text-white lg:text-pxss text-psm font-light mb-5 md:w-full w-80 mx-auto ">
                  {t("hero_text")}
                  </p>

                  <div className="contact-nav  md:hidden  flex justify-center mb-5 ">
                    <Link to="#" className="flex gap-2 ">
                      <img
                        className="lg:w-5 w-5"
                        src="/assets/img/phone-w.svg"
                        alt="phone"
                      />
                      <span className="font-medium text-hmdxp   text-white">
                      {t("phone")}
                      </span>
                    </Link>
                  </div>

                  <div className="px-1 lg:px-0 ">
                    <ToggleStateFnc
                      handleSelectionChange={handleSelectionChange}
                      purchaseData={purchaseData}
                      setPurchaseData={setPurchaseData}
                    />

                    <div className="flex gap-10 bg-color2 px-6 pt-5 pb-3">
                      <div className="pay-total">
                        <span className="block text-green font-light text-psm">
                             {t("total_price")}
                        </span>
                        <b className="text-green ">
                          {purchaseData.totalPrice} {purchaseData.CurrencyName}
                        </b>
                      </div>
                      <div className="time-total">
                        <span className="block text-green font-light text-pxss">
                           {t("vinyet_g_suresi")}
                        </span>
                        <b className="text-green ">
                          {purchaseData.startDate &&
                            purchaseData.startDate +
                              " ile " +
                              purchaseData.finishDate}{" "}
                        </b>
                      </div>
                    </div>
                    <button
                      onClick={handlePurchase}
                      type=""
                      className="bg-orange w-full text-white py-4 rounded-b-lg font-bold"
                    >
                       {t("h_satin_al")}
                    </button>
                  </div>

                  <div className="flex">
                    <img
                      className="w-96 lg:-mx-0 mx-auto"
                      src="/assets/img/credit.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-2/5 lg:block md:hidden hidden">
              <img
                className="w-full  h-full object-cover rounded-tr-3xl rounded-br-3xl"
                src="/assets/img/hero.png"
              />
            </div>
          </div>
        </div>
      </div>

      {/* <References /> */}
      <WhyVn />

      <WhyTolltake />

      <Comment />

      <Faq />
    </div>
  );
};

export default Home;
