import { RiCloseLargeFill, RiMoneyEuroCircleLine,RiAlertFill } from "@remixicon/react";


import { DangerousHTML } from "../content/DangerousHTML";
import { LoadingSpinner } from "../spinner/LoadingSpinner";

export const PaymentForm = ({
  paymentLoading,
  formDataPayment,
  handlePaymentChange,
  PaymentCheckHandle,
  ClosePaymentHandle,
  payment3D,
  invoiceForm,
  totalPriceBuy,
  emailForm,
  errorValCard,
  nameForm,
  formData,
  surnameForm,
  isCheckedSms,
  Auth,
}) => {
  let isFormCompleted = true;

  if (!Auth) {
    isFormCompleted = emailForm && nameForm && surnameForm; // Assign the value to the existing variable
  }

  let isFormInvoices;

  if (invoiceForm) {
    isFormInvoices =
      formData.Invoice.Name &&
      formData.Invoice.LastName &&
      formData.Invoice.Email &&
      formData.Invoice.TownName &&
      formData.Invoice.CityName &&
      formData.Invoice.Postcode &&
      formData.Invoice.Address &&
      formData.Invoice.Phone;
  } else {
    isFormInvoices = true;
  }

  let isFormSms;
  if (isCheckedSms) {
    isFormSms = formData.Phone;
  } else {
    isFormSms = true;
  }

  return (
    <div>
      <div className="payment-form">
        {!paymentLoading ? (
          <div className="link-close-payment cursor-pointer">
            <RiCloseLargeFill onClick={ClosePaymentHandle} />
          </div>
        ) : null}

        <div className="car-box">
          <div className="grid justify-center">
            <h2 className="py-header">
              <RiMoneyEuroCircleLine /> PAYMENT DETAILS
            </h2>
            <div className="pay-total">{totalPriceBuy.toFixed(2)} Euro</div>
          </div>
          {isFormCompleted ? (
            isFormInvoices ? (
              isFormSms ? (
                <div>
                  <form>
                    <h3>CREDIT CARD NUMBER</h3>
                    <div className="item-payment">
                      <div className="item-o">
                        <label>
                          <img src="assets/img/card-list.png" alt="Card" />
                        </label>
                        <input
                          placeholder="1234 1234 1234 1234"
                          maxLength={19}
                          name="number"
                          value={formDataPayment.number2}
                          onChange={handlePaymentChange}
                        />
                      </div>
                    </div>
                    <div className="item-payment flex gap-5">
                      <div className="w-1/2">
                        <h3>EXPIRY DATE</h3>
                        <div className="item-o">
                          <input
                            placeholder="MM/YYYY"
                            name="expireDate"
                            type="text"
                            value={formDataPayment.expireDate}
                            onChange={handlePaymentChange}
                          />
                        </div>
                        {errorValCard && (
                          <div className="errr-card">
                          <RiAlertFill />  {errorValCard}
                          </div>
                        )}
                      </div>
                      <div className="w-1/2">
                        <h3>
                          CVV <span>CODE</span>
                        </h3>
                        <div className="item-o relative">
                          <label>
                            <img
                              className="ccv-card"
                              src="assets/img/card.svg"
                              alt="Card"
                            />
                          </label>
                          <input
                            placeholder="CVC"
                            name="cvc"
                            maxLength={3}
                            value={formDataPayment.cvc}
                            onChange={handlePaymentChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item-payment">
                      <h3>HOLDER NAME</h3>
                      <div className="item-o">
                        <input
                          placeholder="Kart Üzerindeki Adınız Soyadınız"
                          name="holderName"
                          value={formDataPayment.holderName}
                          onChange={handlePaymentChange}
                        />
                      </div>
                      <p className="err-payment">
                        Yapmış olduğunuz işleme ait tutar,{" "}
                        <span>kart ekstrenize farklı yansıyabilir</span>. Bu
                        durum, VISA/MASTERCARD kuruluşlarının ücret
                        politikalarından kaynaklanmaktadır.
                      </p>
                    </div>
                    <div>
                      {paymentLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <button
                          type="button"
                          onClick={PaymentCheckHandle}
                          className="bg-orange py-4 rounded-md mt-4 text-white font-semibold text-hsm w-full"
                        >
                          Ödeme Yap
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div className="text-center text-red-500">
                  Lütfen SMS göndermemiz için telefon numaranızı giriniz.
                </div>
              )
            ) : (
              <div className="text-center text-red-500">
                Lütfen zorunlu fatura alanlarını doldurunuz.
              </div>
            )
          ) : (
            <div className="text-center text-red-500">
              Lütfen önce e-posta, ad ve soyad bilgilerinizi girin.
            </div>
          )}
          <DangerousHTML htmlContent={payment3D} />
        </div>
      </div>
      <div className="overlay"></div>
    </div>
  );
};
