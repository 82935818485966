import React from "react";
import { useTranslation } from "react-i18next";

export const CouponCode = ({ handlecouponCodePlateClick, purchaseData }) => {
    const { t } = useTranslation();

    return (
        <div className="item-o bg-color8 rounded-lg p-4 mb-3">
            <div className="flex items-center justify-between">
                <div className="grid gap-2 items-center">
                    <div className="flex text-psm gap-2 text-green font-semibold">
                        <img src="assets/img/indirim.svg" alt="Discount" />
                        <label>{t('kupon_kodu')}</label>
                    </div>
                    <div className="flex rd-inp rounded-lg">
                        <input className="px-5 py-1 border border-solid border-teal-900 rounded-tl-lg rounded-bl-lg w-[200px]" placeholder={t('kodu_giriniz')} value={purchaseData.couponCode} onChange={(e) => handlecouponCodePlateClick(e.target.value)} />
                        <button className="bg-green border border-solid border-teal-900 br-none px-8 py-1 text-white rounded-tr-lg rounded-br-lg">{t('ekle')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
