import React, { useState, useEffect } from "react";
import { PurchaseProvider  } from "../../contexts/PurchaseContext";
import Stepper from "../../Hooks/Stapper";
import { useLocation,Link } from 'react-router-dom';




const BuyPage = () => {
  const location = useLocation();
  const [purchaseData, setPurchaseData] = useState(location.state ? location.state.state : null);
 

  const handleSelectionChange = (field, value) => {
    setPurchaseData(prev => ({ ...prev, [field]: value }));
};

  return (
    <>   
  
      <div className=" h-full ">
        <div className="relative">
          <img className="bg-hero-p" src="/assets/img/hero-bg-f.png"/>
          <div className="w-form-a table mx-auto">
          <Stepper beforePurchaseData={purchaseData} setBeforePurchaseData={setPurchaseData} handleSelectionChange={handleSelectionChange} />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default BuyPage;
