import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { useLocalization } from '../../contexts/LocalizationContext';
import { createMarkup } from '../../helpers/utils';
import parse from 'html-react-parser';
import Loading from "../../component/loading/Loading";
import SEO from "../../component/content/Seo";



const PageComponent = ({ id, languageId }) => {
  const localization = useLocalization();
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/v1/Page/Get`, {
        params: {
          DataId: id,
          LanguageId: languageId,
        },
      })
      .then((response) => {

        const safeHTML = DOMPurify.sanitize(response.data.PageText);
        response.data.PageText = safeHTML;

    

        setPageContent(response.data);
      })
      .catch((error) => {
        console.error("Sayfa içeriği yüklenemedi!", error);
      });
  }, [id, languageId]);

  if (!pageContent)
    return (
      <div>
            <Loading />
      </div>
    );

  return (

    <div>
            <SEO
    title={pageContent.Data.PageName}
    description={pageContent.Data.MetaDesc}
    keywords={pageContent.Data.MetaKey}
    author="TollTake"
    image="https://www.tolltake.com/assets/img/tolltake-logo.svg"

  />
      <div className="container mx-auto">
        <div className="head-page lg:mx-0 mx-4 ">
          <div className="lg:flex gap-5 grid lg:justify-between justify-center">
            <div>
              <h1>{pageContent.Data.PageName}</h1>
              <ul>
                <li>
                  <Link> Anasayfa</Link>
                </li>
                <li>{pageContent.Data.PageName}</li>
              </ul>
            </div>
            <div>
              <img
                className="table m-auto"
                src="/assets/img/trustpilot-logo.svg"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="lg:mx-0 mx-5 page-text">
          <div
            dangerouslySetInnerHTML={{ __html: pageContent.Data.PageText }}
          />



        </div>
      </div>
    </div>
  );
};

export default PageComponent;
