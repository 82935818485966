export const defaultPurchaseData  = {
        country: 0,
        countryName: '',
        countryImg: '',
        vehicleType: 0,
        vehicleTypeImg: '',
        vehicleTypeName: '',
        duration: 0,
        durationName: '',
        startDate: '',
        finishDate: '',
        lisenceCountry: 0,
        lisenceCountryName: '',
        lisenceCountryImg: '',
        lisencePlate: '',
        totalPrice: 0,
        vignette: '',
        CurrencyId: '',
        CurrencyName: '',
        discountPrice: 0,
        couponCode: ''
  };
  