export const OrderSummary = ({ cartItems, totalPrice, handlePrev }) => (
    <div className="item-o bg-color8 rounded-lg p-4 mb-3">
        <div className="flex items-center justify-between">
            <div className="flex gap-2 items-center">
                <div>
                    <img src="assets/img/road-g.svg" alt="Road" />
                </div>
                <div className="grid gap-0">
                    <span className="text-green px-3 inline-block text-psm rounded-xl font-bold">Sipariş Özeti</span>
                    <span className="font-light text-psm text-green inline-block pl-3">{cartItems.length} Adet Vinyet</span>
                </div>
            </div>
            <div>
                <div className="text-right mb-2">{totalPrice} Euro</div>
                <div onClick={handlePrev} className="border text-orange hover:text-green cursor-pointer px-6 py-1">
                    Sepeti Görüntüle
                </div>
            </div>
        </div>
    </div>
);