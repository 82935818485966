import React, { useState, useEffect } from "react";
import { getComment } from "../../services/commentService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
const Comment = () => {
  const [listComment, setList] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getComment(15, 1);

        setList(apiList.Data);
      } catch (error) {
        console.error("API error:", error);
      }
    };

    loadData();
  }, []);

  return (
    <div>
      <div className="bg-green mb-20 mt-10 lg:pt-28 pt-20 lg:pb-14 pb-32 relative overflow-hidden">
        <img
          className="absolute top-[-45px] left-[-15px] lg:block hidden"
          src="assets/img/world-image-1.png"
        />
        <img
          className="absolute h-full top-[0px] w-full object-cover lg:hidden block"
          src="assets/img/world-image-1-m.png"
        />
        <div>
          <div className="lg:flex block bf-color gap-3">
            <div className="text-white lg:w-1/3  lg:relative  lg:top-[-62px] top-[0] lg:pl-16 pl-3   ">
              {listComment && (
                <h2
                  className="lg:text-hmd text-hmdp mb-3 leading-9 font-medium"
                  dangerouslySetInnerHTML={{ __html: listComment.Description }}
                />
              )}

              {listComment && (
                <h2
                  className="mb-8 text-hspb font-light"
                  dangerouslySetInnerHTML={{ __html: listComment.Text }}
                />
              )}

              <div id="customPagination"></div>
            </div>

            <div className="lg:w-2/3 lg:pl-0 pl-3  ">
              <div>
                {listComment && listComment.Items && (
                  <Swiper
                    slidesPerView={window.innerWidth > 768 ? 2.5 : 1.5}
                    spaceBetween={window.innerWidth > 768 ? 50 : 20}
                    pagination={{
                      el: "#customPagination",
                      clickable: true,
                    }}
                    modules={[Pagination]}
                    className="comment-h"
                  >
                    {listComment.Items.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="item-sw bg-color6 px-10 py-10 rounded-lg">
                          <p
                            className="text-green text-hsm"
                            dangerouslySetInnerHTML={{
                              __html: item.Text,
                            }}
                          />
                          <div className="text-green text-hspb c-name mt-7 font-bold">
                            {item.Description}
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
