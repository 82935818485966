import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { useLocalization } from "../../contexts/LocalizationContext";
import { createMarkup } from "../../helpers/utils";
import parse from "html-react-parser";
import Loading from "../../component/loading/Loading";
import SEO from "../../component/content/Seo";

const CountryComponent = ({ id, languageId }) => {
  const localization = useLocalization();
  const BASE_URL = process.env.REACT_APP_API_URL;

  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/v1/VignetteCountry/Get`, {
        params: {
          CountryId: id,
          LanguageId: languageId,
        },
      })
      .then((response) => {
        const safeHTML = DOMPurify.sanitize(response.data.Detail);
        response.data.Detail = safeHTML;

        console.log(response);

        setPageContent(response.data);
      })
      .catch((error) => {
        console.error("Sayfa içeriği yüklenemedi!", error);
      });
  }, [id, languageId]);

  if (!pageContent)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className="container mx-auto ">
           <SEO
    title={pageContent.Data.Title}
    description={pageContent.Data.MetaDesc}
    keywords={pageContent.Data.MetaKey}
    author="TollTake"
    image={pageContent.Data.ProductCategoryImg}

  />
      <div dangerouslySetInnerHTML={{ __html: pageContent.Data.Detail }} />
    </div>
  );
};

export default CountryComponent;
