import React, { useState, useEffect } from 'react';

function Typewriter() {
  const countries = ["Bulgaristan", "Romanya", "Avusturya", "Macaristan"];
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [reverse, setReverse] = useState(false);

  // Effect for typing and deleting the text
  useEffect(() => {
    if (subIndex === countries[index].length + 1 && !reverse) {
      setTimeout(() => setReverse(true), 1000); // Wait 1 second before starting to delete
    } else if (subIndex === 0 && reverse) {
      setReverse(false);
      setIndex((prevIndex) => (prevIndex + 1) % countries.length); // Move to the next country
    }

    if (reverse && subIndex > 0) {
      setTimeout(() => setSubIndex((prevSubIndex) => prevSubIndex - 1), 100);
    } else if (!reverse && subIndex <= countries[index].length) {
      setTimeout(() => setSubIndex((prevSubIndex) => prevSubIndex + 1), 100);
    }
  }, [subIndex, reverse, index]);

  return (
    <h1 className="text-white lg:text-hlg text-hlgm font-medium md:mb-3 mb-5 lg:pt-0 pt-12 leading-none">
      Güvenli Vinyet
      <span className="text-color7 lg:inline pl-3 block minheight32">
        {countries[index].substring(0, subIndex)}
      </span>
    </h1>
  );
}

export default Typewriter;
