import React, { useState, useEffect } from "react";
import { getFaq } from "../../services/faqService";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { RiArrowDownSLine } from "@remixicon/react";
import "swiper/css";
import "swiper/css/pagination";
import Loading from "../loading/Loading";
const Faq = () => {
  const [listFaq, setList] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getFaq(13, 1);
        setList(apiList.Data);
      } catch (error) {
        console.error("API error:", error);
      }
    };

    loadData();
  }, []);
  
  if (listFaq) {
    return (
      <div className="container mx-auto  mb-20">
        <div className="mx-7">
         
  
          {listFaq &&
            listFaq.Items &&
            listFaq.Items.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<RiArrowDownSLine />}
                  className="font-semibold text-hspba"
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {item.Name}
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="text-green text-hsm"
                    dangerouslySetInnerHTML={{
                      __html: item.Text,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
      </div>
    );
  }else {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  
};

export default Faq;
