import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";

import PageComponent from "./content/PageComponent";

import axios from "axios";
import CountryComponent from "./content/CountryComponent";

const DynamicCountryLoader = () => {
  const { slug } = useParams();
  const [pageType, setPageType] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const link = `${BASE_URL}/v1/Route/GetSlug?slug=${slug}`;

    axios
      .get(link)
      .then((response) => {
        const data = response.data;
        if (data.Success) {
          setPageType(data.Data.TypeID);
          setPageId(data.Data.DataID);
          setLanguageId(data.Data.LanguageID);
        } else {
          throw new Error(data.Message);
        }
      })
      .catch((error) => console.error("Sayfa verileri yüklenemedi:", error));
  }, []);

  return <CountryComponent id={pageId} languageId={languageId} />;
};

export default DynamicCountryLoader;
