import { makeAutoObservable } from 'mobx';
import { jwtDecode } from 'jwt-decode'; 
import CryptoJS from 'crypto-js';
import jwtEncode from 'jwt-encode'; 

class BasketStore {
    basketState = [];
    orderState = null;
    basketStateName = "basketState";
    orderName = "order";

    constructor() {
        makeAutoObservable(this);
        // this.getBasket();
    }

    saveOrder = (orderInfo) => {
        try {
            const order  =  JSON.stringify(orderInfo);
    
            const KeyRandom = process.env.REACT_APP_RANDOM_KEY;
            const jwt = jwtEncode(order, "secret"); 
            const encrypted = CryptoJS.AES.encrypt(jwt, KeyRandom).toString();
            localStorage.setItem(this.orderName, encrypted);
            // this.getState();
        } catch (e) {
            console.log("Error saving basket:", e);
        }
    }

    getOrder = () => {
        try {
            const KeyRandom = process.env.REACT_APP_RANDOM_KEY;

            const orderStateData = localStorage.getItem(this.orderName);

            if (orderStateData) {
   
                const bytes = CryptoJS.AES.decrypt(orderStateData, KeyRandom);
                const originalText = bytes.toString(CryptoJS.enc.Utf8);
                const basketCrypto = jwtDecode(originalText);
                return JSON.parse(basketCrypto);
             
              
               
            } else {
                this.orderState = null;
            }
        } catch (e) {
            console.log("Error getting order:", e);
        }
    }

    saveBasket = (cart) => {
        try {
            const basketState  =  JSON.stringify(cart);
    
            const KeyRandom = process.env.REACT_APP_RANDOM_KEY;
            const jwt = jwtEncode(basketState, "secret"); 
            const encrypted = CryptoJS.AES.encrypt(jwt, KeyRandom).toString();
            localStorage.setItem(this.basketStateName, encrypted);
            // this.getState();
        } catch (e) {
            console.log("Error saving basket:", e);
        }
    }

    getBasket = () => {
        try {
           
            const KeyRandom = process.env.REACT_APP_RANDOM_KEY;

            const basketStateData = localStorage.getItem(this.basketStateName);

            if (basketStateData) {
   
                const bytes = CryptoJS.AES.decrypt(basketStateData, KeyRandom);
                const originalText = bytes.toString(CryptoJS.enc.Utf8);
                const basketCrypto = jwtDecode(originalText);
                
                this.basketState = JSON.parse(basketCrypto)
                return this.basketState;
                
              
               
            } else {
                this.basketState = [];
                return [];
            }
        } catch (e) {
            console.log("Error getting basket:", e);
        }
    }

    removeBasket= () => {
        localStorage.removeItem(this.basketStateName);
      
    }
    removeOrder= () => {
        localStorage.removeItem(this.orderName);
      
    }
   
}

const basketStore = new BasketStore();
export default basketStore;
