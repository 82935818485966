import { makeAutoObservable } from 'mobx';
import { jwtDecode } from 'jwt-decode'; 
import CryptoJS from 'crypto-js';
import jwtEncode from 'jwt-encode'; // jwt-encode modülünün doğru kullanımı

class AuthStore {
    appState = null;
 

    
    constructor() {
        makeAutoObservable(this);
        this.getToken();

    }

    saveToken = (appState) => {
        try {
            const KeyRandom = process.env.REACT_APP_RANDOM_KEY;
            const jwt = jwtEncode(appState, "secret"); 
            const jwtExpire = jwtEncode(appState.user.ExpireTime, "secret"); 
            const encrypted = CryptoJS.AES.encrypt(jwt, KeyRandom).toString();
            const encryptedExpire = CryptoJS.AES.encrypt(jwtExpire, KeyRandom).toString();
            localStorage.setItem('appState', encrypted);
            localStorage.setItem('appStateEx', encryptedExpire);
            this.getToken();
        } catch (e) {
            console.log("Error saving token:", e);
        }
    }

    getToken = () => {
        try {
            const KeyRandom = process.env.REACT_APP_RANDOM_KEY;
            const now = new Date();
        

            const tokenExpiration = localStorage.getItem('appStateEx');

            if(tokenExpiration){
         
                const bytes = CryptoJS.AES.decrypt(tokenExpiration, KeyRandom);
                const originalText = bytes.toString(CryptoJS.enc.Utf8);
              const decodeTime =  jwtDecode(originalText);

        
                
            const expireTime = new Date(decodeTime);
            console.log(expireTime);
            
            if (now.getTime() > expireTime.getTime()) 
            {
                this.removeToken();
           
                this.appState = null;
              console.log('Token expired, logout the user or refresh the token');
            }
            }
            else{
               
            }
            
            const appStateData = localStorage.getItem("appState");

            if (appStateData) {
                const bytes = CryptoJS.AES.decrypt(appStateData, KeyRandom);
                const originalText = bytes.toString(CryptoJS.enc.Utf8);
                this.appState = jwtDecode(originalText);
            } else {
                this.appState = null;
            }
        } catch (e) {
            console.log("Error getting token:", e);
        }
    }

    removeToken = () => {
        localStorage.removeItem("appState");
        localStorage.removeItem("appStateEx");
        this.appState = null;
    }

    get isLoggedIn() {
        return this.appState !== null; // isLoggedIn computed property
    }
}

const authStore = new AuthStore();
export default authStore;
