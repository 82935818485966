import React, { useRef, useState, Fragment, useEffect } from "react";
import { usePurchase } from "../../contexts/PurchaseContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function StepTwo({ handleNext, isLastStep, handlePrev }) {
  const { t } = useTranslation();

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const { purchaseData } = usePurchase();
  const { updatePurchaseData } = usePurchase();
  const [selectedTab, setSelectedTab] = useState(1);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const handleTabClick = (vehicleType) => {
    setSelectedTab(vehicleType.Id);

    updatePurchaseData("vehicleType", vehicleType.Id);
    updatePurchaseData("vehicleTypeName", vehicleType.Name);
    updatePurchaseData("vehicleTypeImg", vehicleType.Icon);
  };

  const handleDurationClick = (
    duration,
    totalPrice,
    CurrencyName,
    CurrencyId,
    vignetteId,
    durationName
  ) => {
    updatePurchaseData("durationName", durationName);
    updatePurchaseData("duration", duration);
    updatePurchaseData("totalPrice", totalPrice);

    updatePurchaseData("CurrencyName", CurrencyName);
    updatePurchaseData("currency", CurrencyId);
    updatePurchaseData("vignette", vignetteId);
    handleNext();
  };

  useEffect(() => {
    if (selectedVehicle) {
      updatePurchaseData("vehicleType", selectedVehicle.Id);
      updatePurchaseData("vehicleTypeName", selectedVehicle.Name);
      updatePurchaseData("vehicleTypeImg", selectedVehicle.Icon);
    }
  }, [selectedVehicle]);

  // useEffect(() => {
  //   // if(beforePurchaseData){
  //   //   handleDurationClick(beforePurchaseData.duration, beforePurchaseData.totalPrice, beforePurchaseData.CurrencyName, beforePurchaseData.CurrencyId, beforePurchaseData.vignette);
  //   // }
  // }, [beforePurchaseData]);

  // if (purchaseData.country < 1) {
  //   handlePrev();
  // }

  useEffect(() => {
    if (purchaseData.country < 1) {
      handlePrev();
    } else {
      axios
        .get(`${BASE_URL}/v1/VehicleType/GetAllTypeVignette`, {
          params: {
            LanguageId: 1,
            CountryId: purchaseData.country,
          },
        })
        .then((response) => {
          if (response.data.Success) {
            setVehicles(response.data.Data);

            if (response.data.Data[0]) {
              setSelectedVehicle(response.data.Data[0]);
            } else {
              setVehicles([]);
              setSelectedVehicle(null);
            }
          }
        })
        .catch((error) => {
          console.error("Veri Gönderilemedi", error);
        });
    }
  }, [purchaseData.country]);

  return (
    <div>
      <h2 className="text-color7 text-hmdx2 font-light mb-1">
        {t("arac_tipi_seciniz")}
      </h2>
      <p className="text-white font-light text-psm">{t("arac_tipi_text")}</p>

      <ul class="lg:flex grid gap-4 mt-4 text-center    grid-cols-2">
        {vehicles.map((vehicle) => (
          <li
            key={vehicle.Id}
            className={`px-6 py-2 rounded-lg text-sxps grid ${
              selectedTab === vehicle.Id ? "bg-color2" : "bg-bg5"
            }`}
            onClick={() => handleTabClick(vehicle)}
          >
            {vehicle.Icon && (
              <img
                className="w-10 h-10 table m-auto"
                src={vehicle.Icon}
                alt={vehicle.Name}
              />
            )}
              {!vehicle.Icon && (
              <img
                className="w-10 h-10 table m-auto"
                src="/assets/img/tolltake-w.png"
                alt={vehicle.Name}
              />
            )}

            <span>{vehicle.Name} </span>
          </li>
        ))}
      </ul>

      {vehicles.map((vehicle) => (
        <div
          key={vehicle.Id}
          className={`mt-8 ${selectedTab !== vehicle.Id ? "hidden" : ""}`}
        >
          {vehicle.Vignettes.map((vignette) => (
            <div
              key={vignette.Id}
              className="item-o bg-color8 rounded-lg p-4 mb-3"
              onClick={() =>
                handleDurationClick(
                  vignette.Duration,
                  vignette.DiscountPrice,
                  vignette.CurrencyName,
                  vignette.CurrencyId,
                  vignette.Id,
                  vignette.Name
                )
              }
            >
              <div className="flex items-center justify-between">
                <div className="flex gap-4 items-center">
                  <div>
                    <img src="assets/img/road.svg" alt="Road icon" />
                  </div>
                  <div className="grid gap-1">
                    <span
                      className={`${
                        vignette.Duration === 2
                          ? "bg-bg1"
                          : vignette.Duration === 10
                          ? "bg-bg2"
                          : vignette.Duration === 30
                          ? "bg-bg3"
                          : "bg-bg4"
                      } text-white px-3 py-1 inline-block text-psm rounded-xl font-medium`}
                    >
                      {vignette.Name}
                    </span>
                    <span className="font-bold text-psm text-green inline-block pl-3">
                      {vignette.DiscountPrice} {vignette.CurrencyName}
                    </span>
                  </div>
                </div>

                {vehicle.Icon && (
                  <img
                    className="w-10 h-10 "
                    src={vehicle.Icon}
                    alt={vehicle.Name}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
