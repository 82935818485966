import React ,{useState}from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const SmsCheckbox = ({
  isCheckedSms,
  handleSmsChange,
  handleInputChange,
  formData,
  handleSMSClick,
}) => {
  const [phone, setPhone] = useState("");

  return (
    <div className="item-o bg-color8 rounded-lg p-4 mb-3">
      <div className="flex items-center text-green justify-between">
        <div className="flex">
          <input
            id="sms-checkbox"
            type="checkbox"
            value="1"
            checked={isCheckedSms}
            onChange={handleSmsChange}
            className="relative w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="sms-checkbox"
            className="ms-2 text-gray-900 text-green text-psm font-bold relative"
          >
            SMS olarak kayıt onayı
            <div className="font-light">
              Cep telefonunuza bizden SMS'le hemen bir kayıt onayı alacaksınız.
            </div>
          </label>
        </div>
        <div>
          <div className="text-green font-semibold">0,99 €</div>
        </div>
      </div>
      {isCheckedSms && (
        <div className="inp-on mt-5">
          <label>Cep Telefonu Numarası</label>
          <PhoneInput
            value={phone}
            name="Phone"
            onChange={(value) => {
              setPhone(value);
              handleSMSClick(value);
            }}
            country={"tr"}
          />
        </div>
      )}
    </div>
  );
};
