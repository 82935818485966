// utils.js

export function createMarkup(htmlContent) {
  return { __html: htmlContent };
}

export const formatDate = (dateString) => {
  const formattedDate = dateString.replace(
      /(\w+) (\d+)(st|nd|rd|th), (\d+) (\d+):(\d+)/,
      "$1.$2.$4 $5:$6"
  );
  const date = new Date(formattedDate);
  return date
      .toLocaleDateString("tr-TR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
      })
      .replace(/\./g, "/");
};
