import { Icon } from "@mui/material";
import { Alert } from "reactstrap";

export const ErrorAlert = ({ errorVal }) => (
    <div className="mb-3 form-auth">
        <Alert color="danger" className="alert-icon login-alert">
            <Icon name="alert-circle" /> {errorVal}
        </Alert>
    </div>
);
