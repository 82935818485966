import React, { useState, useEffect } from "react";
import { getwhyVnServices } from "../../services/whyVnServices";

const WhyVn = () => {
  const [listTolltake, setList] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getwhyVnServices(38, 1);

        setList(apiList);
      } catch (error) {
        console.error("API error:", error);
      }
    };

    loadData();
  }, []);

  return (
    <div>
      <div className="container mx-auto mt-16 lg:px-12 px-0">
        <h2 className="text-green   text-hmdx2 font-semibold lg:text-center text-left lg:pl-0 pl-4">
          {listTolltake && listTolltake.Data.PageDesc}
        </h2>

        {listTolltake && (
          <div className="why-vn-1"
            dangerouslySetInnerHTML={{ __html: listTolltake.Data.PageText }}
          />
        )}
      </div>
    </div>
  );
};

export default WhyVn;
