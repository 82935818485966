import axios from 'axios';


const BASE_URL = process.env.REACT_APP_API_URL;


export const getComment = async (id,langid) => {

  const postData = {
    listId:id,
    languageId: langid
  };

  try {
    const response = await axios.post(`${BASE_URL}/v1/List/Get`, postData);
    if (response.data.Success) {
      return response.data;
    } else {
      console.error('API returned an error:', response.data.Message);
      return [];  
    }
  } catch (error) {
    console.error("Veri Gönderilemedi", error);
    throw error; 
  }
};




