// ProtectedRoute.js

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import authStore from "../Store/AuthStore";

const ProtectedRoute = observer(({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authStore.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
});

export default ProtectedRoute;
