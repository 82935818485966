import React, { useState, Fragment, useEffect } from "react";
import Faq from "../../component/homepages/Faq";
import { Link } from "react-router-dom";
export default function Index() {
  return (
    <div>
      <div className="container mx-auto">
        <div className="head-page lg:mx-0 mx-4 ">
          <div className="lg:flex gap-5 grid lg:justify-between justify-center">
            <div>
              <h1>TollTake İle İlgili Merak Edilenler</h1>
              <ul>
                <li>
                  <Link> Anasayfa</Link>
                </li>
                <li>Sıkça Sorulan Sorular</li>
              </ul>
            </div>
            <div>
              <img
                className="table m-auto"
                src="/assets/img/trustpilot-logo.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="faq-page">
        <Faq />
      </div>
    </div>
  );
}
