import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

const defaultLanguage = 'tr';

const updateTimeKey = `i18next_update_time_${defaultLanguage}`;
const storedResources = localStorage.getItem(`i18next_res_${defaultLanguage}`);
const storedUpdateTime = localStorage.getItem(updateTimeKey);

const initialResources = storedUpdateTime ?  (storedResources ? JSON.parse(storedResources) : null) : null;

const BASE_URL = process.env.REACT_APP_API_URL;

i18n
.use(HttpApi)
.use(initReactI18next)
.init({
  // resources: initialResources ? { [defaultLanguage]: { translation: initialResources } } : undefined,
  supportedLngs: ['tr'],
  fallbackLng: "tr", 
  debug: true,
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    caches: ['localStorage', 'cookie'],
  },
  backend: {
    loadPath: `${BASE_URL}/v1/Localization/Get?LanguageCode={{lng}}&UpdateTime=${storedUpdateTime || ''}`,
    parse: function(data) {
      const json = JSON.parse(data);
      const resources = {};

      if (json.Success && json.Data && json.Data.Resources && json.Data.UpdateTime) {
   
        json.Data.Resources.forEach(item => {
          resources[item.name] = item.value; 
        });
        
        localStorage.setItem(`i18next_res_${i18n.language}`, JSON.stringify(resources));
        localStorage.setItem(updateTimeKey, json.Data.UpdateTime);
      }
      else if(json.Success && json.Data==null && initialResources){
      
       return initialResources;
      }
      return resources;
    }
    
    
  },
  interpolation: {
    escapeValue: false 
  }
});

export default i18n;
