export const TermsAndConditions = ({ isChecked, setIsChecked, handleCheckboxChange }) => (
    <div>
        <div className="flex items-center">
            <input id="link-checkbox" type="checkbox" value="1" checked={isChecked} onChange={() => setIsChecked(!isChecked)} className="w-4 h-4 relative text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="link-checkbox" className="ms-2 text-gray-900 text-white text-psm font-light">
                <a href="javascript:void(0)" className="font-bold underline" onClick={() => handleCheckboxChange(true, 32)}>Satış Sözleşmesi,</a>
                . <a href="javascript:void(0)" className="font-bold underline" onClick={() => handleCheckboxChange(true, 33)}>Kullanım Koşulları</a> ve
                <a href="javascript:void(0)" className="font-bold underline" onClick={() => handleCheckboxChange(true, 34)}>Ödeme Şartları</a> kapsamında kişisel verilerimin işlenmesine onay veriyorum.
            </label>
        </div>
    </div>
);