import React, { useState, useEffect, useRef } from "react";


export const DangerousHTML = ({ htmlContent }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
          const scripts = containerRef.current.querySelectorAll("script");
          scripts.forEach((script) => {
            const scriptText = script.innerText;

            const newScript = document.createElement("script");
            newScript.text = script.innerText;
            document.body.appendChild(newScript);

            try {
              eval(scriptText);
              const funcCalls = scriptText.match(/function\s+(\w+)\s*\(/g);
              if (funcCalls) {
                funcCalls.forEach((funcCall) => {
                  const funcName = funcCall.split(" ")[1].split("(")[0];
                  eval(`${funcName}();`);
                });
              }
            } catch (error) {
              console.error("Script execution error:", error);
            }
          });
        }
      }, [htmlContent]);

    return <div ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlContent }}></div>;
};