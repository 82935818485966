import { RiCloseLargeFill, RiMoneyEuroCircleLine,RiAlertFill } from "@remixicon/react";
import React, { useState, useEffect } from 'react';
import { CardElement,PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { DangerousHTML } from "../content/DangerousHTML";
import { LoadingSpinner } from "../spinner/LoadingSpinner";
import axios from 'axios';

export const StripePaymentForm = ({
  paymentLoading,
  formDataPayment,
  handlePaymentChange,
  PaymentCheckHandle,
  setErrorCard,
  ClosePaymentHandle,
  payment3D,
  invoiceForm,
  totalPriceBuy,
  emailForm,
  errorValCard,
  clientSecret,
  urlCallBack,
  nameForm,
  formData,
  surnameForm,
  isCheckedSms,
  Auth,
}) => {
    const BASE_URL = process.env.REACT_APP_API_URL;

    const stripe = useStripe();
    const elements = useElements();
  let isFormCompleted = true;

  if (!Auth) {
    isFormCompleted = emailForm && nameForm && surnameForm; // Assign the value to the existing variable
  }

  // useEffect(() => {
  //   // PaymentIntent oluşturmak için backend'e istek gönder
  //   axios.post(`${BASE_URL}/v1/StripePayment/CreatePaymentIntent`, {
  //     amount: totalPriceBuy,
  //     receiptEmail: emailForm
  //   })
  //   .then(response => {
  //     setClientSecret(response.data.data.clientSecret); // Adjust according to your API response structure
  //   })
  //   .catch(error => {
  //     console.error('Error creating payment intent:', error);
  //   });
  // }, [totalPriceBuy, emailForm]);


  let isFormInvoices;

  if (invoiceForm) {
    isFormInvoices =
      formData.Invoice.Name &&
      formData.Invoice.LastName &&
      formData.Invoice.Email &&
      formData.Invoice.TownName &&
      formData.Invoice.CityName &&
      formData.Invoice.Postcode &&
      formData.Invoice.Address &&
      formData.Invoice.Phone;
  } else {
    isFormInvoices = true;
  }

  let isFormSms;
  if (isCheckedSms) {
    isFormSms = formData.Phone;
  } else {
    isFormSms = true;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }


    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: urlCallBack,
      },
    });

    if (error) {
      setErrorCard(error.message);
      console.log('[error]', error);
    } else {
      console.log('Payment successful!');
    }
  };
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  return (
    <div>
      <div className="payment-form">
        {!paymentLoading ? (
          <div className="link-close-payment cursor-pointer">
            <RiCloseLargeFill onClick={ClosePaymentHandle} />
          </div>
        ) : null}

        <div className="car-box">
          <div className="grid justify-center">
            <h2 className="py-header">
              <RiMoneyEuroCircleLine /> PAYMENT DETAILS
            </h2>
            <div className="pay-total">{totalPriceBuy.toFixed(2)} Euro</div>
          </div>
          {isFormCompleted ? (
            isFormInvoices ? (
              isFormSms ? (
                <div>
                  <form onSubmit={handleSubmit}>
                    <h3>CREDIT CARD NUMBER</h3>
                    <div className="item-payment">
                    <PaymentElement options={cardElementOptions} />


                    </div>
                    <div>
                      {paymentLoading ? (
                        <LoadingSpinner />
                      ) : (
                        <button
                          type="submit"
                          className="bg-orange py-4 rounded-md mt-4 text-white font-semibold text-hsm w-full"
                        >
                          Ödeme Yap
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              ) : (
                <div className="text-center text-red-500">
                  Lütfen SMS göndermemiz için telefon numaranızı giriniz.
                </div>
              )
            ) : (
              <div className="text-center text-red-500">
                Lütfen zorunlu fatura alanlarını doldurunuz.
              </div>
            )
          ) : (
            <div className="text-center text-red-500">
              Lütfen önce e-posta, ad ve soyad bilgilerinizi girin.
            </div>
          )}
          <DangerousHTML htmlContent={payment3D} />
        </div>
      </div>
      <div className="overlay"></div>
    </div>
  );
};
