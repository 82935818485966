import React, { useRef, useState, Fragment, useEffect } from "react";
import { usePurchase } from "../../contexts/PurchaseContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function StepOne({ isLastStep, handleNext,beforePurchaseData }) {
  const { t } = useTranslation();

  const { updatePurchaseData } = usePurchase();
  const [countries, setCountries] = useState([]);

  const BASE_URL = process.env.REACT_APP_API_URL;

  const handleDayClick = (country,countryName,ProductCategoryImg) => {
    updatePurchaseData("country", country);
    updatePurchaseData("countryName", countryName);
    updatePurchaseData("countryImg", ProductCategoryImg);

    handleNext();
  };



  useEffect(() => {
    if(beforePurchaseData && beforePurchaseData.country){

      // handleDayClick(beforePurchaseData.country,beforePurchaseData.countryName,beforePurchaseData.countryImg);
    }
    axios
      .get(`${BASE_URL}/v1/VignetteCountry/GetList?LanguageId=1`)
      .then((response) => {
        if (response.data.Success) {
          setCountries(response.data.Data);
        }
      })
      .catch((error) => {
        console.error("Veri Gönderilemedi", error);
      });
  }, []);

  return (
    <div>
      <h2 className="text-color7 text-hmdx2 font-light mb-1">{t('ulke_seciniz')} </h2>
      <p className="text-white font-light text-psm">
        {t('ulke_text')}
      </p>

      <ul className="mt-10">
        {countries.map((country) => (
          <li
            key={country.Id}
            className="f-grid mb-4 bg-color8 rounded-lg"
            onClick={() => handleDayClick(country.Id,country.Name,country.ProductCategoryImg)}
            disabled={isLastStep}
          >
            <span className="pl-5 flex gap-3 items-center text-green font-bold">
              <img
                className="w-7 h-5"
                src={`${country.ProductCategoryImg}`}
                alt={country.Name}
              />
              {country.Name}
            </span>
            <img
              className="w-full rounded-l-full  opacity-50 h-24 object-cover"
              src={`${country.ProductCategoryImg2}`}
              alt="City"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}