import React, { useState, Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { getCountryService } from "../../services/getCountryService";
import SEO from "../../component/content/Seo";
export default function Vcontrol() {
  const [selectedCountry, setselectedCountry] = useState(null);
  const [countryS, setcountryS] = useState([]);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const [selectedCountryOne, setselectedCountryOne] = useState(null);

  
  const [selectedCountryTwo, setselectedCountryTwo] = useState(null);
  const [isSearchForm, setisSearchForm] = useState(false);


  const [countries, setCountries] = useState([]);


  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getCountryService(1);
        setCountries(apiList.Data);
      } catch (error) {
        console.error("API error:", error);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/v1/LicenseCountry/GetList?LanguageId=1`)
      .then((response) => {
        if (response.data.Success) {
          setcountryS(response.data.Data);
        }
      })
      .catch((error) => {
        console.error("Veri Gönderilemedi", error);
      });
  }, []);


  const FormSubmitHandle = () => {

  
    setisSearchForm(true)

  }


  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="v-control">
                  <SEO
    title="Vinyet Sorgulama"
    description="Seyahatiniz için gerekli olan vinyetleri hızlıca sorgulayın ve geçerlilik durumlarını kontrol edin. TollTake ile güvenli ve endişesiz bir yolculuk planlayın."
    author="TollTake"
    image="https://www.tolltake.com/assets/img/tolltake-logo.svg"

  />
      <div className="container mx-auto">
        <div className="lg:mx-0 mx-5">
          <h1 className="text-green lg:text-hlg2 mt-5  text-lgheads font-semibold mb-2">
            {" "}
            Vinyet Sorgulama
          </h1>
          <p className="text-green text-hmdxp font-light lg:mb-0 mb-5">
          Seyahatiniz için gerekli olan vinyetleri hızlıca sorgulayın ve geçerlilik durumlarını kontrol edin. TollTake ile güvenli ve endişesiz bir yolculuk planlayın.

          </p>
        </div>
        <div className="bg-w">
          <div className="form-v">
            <div className="lg:w-1/2 w-full">
              <Listbox
                value={selectedCountryOne}
                onChange={setselectedCountryOne}
              >
                {({ open }) => (
                  <>
                    <div className="input-f  mt-0-1">
                      <Listbox.Label className="mb-2 text-left block font-medium text-psm selecet-f text-white">
                        <span>1</span>{" "}
                        <span>
                          {" "}
                          Sorgulamak istediğiniz vinyet ülkesini seçiniz
                        </span>
                      </Listbox.Label>
                    </div>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white  px-5 py-4 text-left text-gray-900 ">
                        <span className="flex items-center">
                          <span className="w-flag-a truncate text-green flex gap-3 font-semibold items-center">
                            <img src="/assets/img/world.svg" />

                            {selectedCountryOne
                              ? selectedCountryOne.Name
                              : "ÜLKE SEÇİNİZ "}
                          </span>
                        </span>

                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Listbox.Options className="hov-a absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {countries &&
                            countries.map((item) => (
                              <Listbox.Option
                                key={item.Id}
                                className={({ active }) =>
                                  classNames(
                                    active ? "" : "text-gray-900",
                                    "cursor-default select-none py-2 pr-9"
                                  )
                                }
                                value={item}
                              >
                                {({ selectedCountryOne, active }) => (
                                  <div className="flex items-center flag-img-select">
                                    <span
                                      className={classNames(
                                        selectedCountryOne
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      <img src={item.ProductCategoryImg} />{" "}
                                      {item.Name}
                                    </span>
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
            <div className="lg:w-1/2 w-full">
              <Listbox
                value={selectedCountryTwo}
                onChange={setselectedCountryTwo}
              >
                {({ open }) => (
                  <>
                    <div className="input-f  mt-0-1">
                      <Listbox.Label className="mb-2 text-left block font-medium text-psm selecet-f text-white">
                        <span>2</span>{" "}
                        <span> Aracınızın kayıtlı olduğu ülkeyi seçiniz.</span>
                      </Listbox.Label>
                    </div>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white  px-5 py-4 text-left text-gray-900 ">
                        <span className="flex items-center">
                          <span className="w-flag-a truncate text-green flex gap-3 font-semibold items-center">
                            <img src="/assets/img/world.svg" />

                            {selectedCountryTwo
                              ? selectedCountryTwo.Name
                              : "ÜLKE SEÇİNİZ "}
                          </span>
                        </span>

                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Listbox.Options className="hov-a absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {countryS &&
                            countryS.map((item) => (
                              <Listbox.Option
                                key={item.Id}
                                className={({ active }) =>
                                  classNames(
                                    active ? "" : "text-gray-900",
                                    "cursor-default select-none py-2 pr-9"
                                  )
                                }
                                value={item}
                              >
                                {({ selectedCountryOne, active }) => (
                                  <div className="flex items-center flag-img-select">
                                    <span
                                      className={classNames(
                                        selectedCountryOne
                                          ? "font-semibold"
                                          : "font-normal",
                                        "ml-3 block truncate"
                                      )}
                                    >
                                      <img src={item.DataCode} /> {item.Name}
                                    </span>
                                  </div>
                                )}
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>

          <div className="plk-f">
            <div className="input-f">
              <label>
                <span>3</span>{" "}
                <span>Sorgulama yapacağınız aracın plakasını giriniz.</span>
              </label>
              <div className="relative">
                <img src="/assets/img/license-plate.svg" />
                <input placeholder="PLAKA"></input>
              </div>
            </div>
          </div>

          <button className="bg-green px-16 font-medium rounded-lg py-3 text-hspa text-white mt-8 hover:bg-orange"  onClick={() => FormSubmitHandle()}>
            SORGULA
          </button>

          {isSearchForm && (
          <div className="message-i" >Lütfen daha sonra tekrar deneyiniz!</div>
        )}
        </div>
      </div>
    </div>
  );
}
