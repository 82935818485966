import React, { useState, useEffect } from "react";
import { getWhyTolltake } from "../../services/whyTolltakeService";

const WhyTolltake = () => {
  const [listTolltake, setList] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getWhyTolltake(17, 1);

        setList(apiList.Data);
      } catch (error) {
        console.error("API error:", error);
      }
    };

    loadData();
  }, []);

  return (
    <div>
      {listTolltake &&
        listTolltake.Items &&
        listTolltake.Items.map((item, index) => (
          <div key={index} className="container mx-auto mt-24">
            <div className="lg:columns-2 md:columns-2  columns-1  gap-10 my-10 mx-4 lg:flex inline-block fl-x  items-center lg:px-32 px-4">
              <div className="w-full max-auto ">
                <h2 className="text-green text-hmdp font-medium mb-3">
                  {item.Name}
                </h2>

                <div
                  className="text-green text-hmdxp sz-vn-1 "
                  dangerouslySetInnerHTML={{ __html: item.Text }}
                />

                <div className="link-home-a">
                  <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                </div>
              </div>

              <div>
                <img className="w-[42rem]" src={item.Img} />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default WhyTolltake;
