import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getwhyVnServices = async (id, langid) => {
  try {
    const response = await axios.get(`https://api.tolltake.com/v1/Page/Get`, {
      params: {
        DataId: id,
        LanguageId: langid
      }
      
    });

 

    if (response.data.Success) {
      return response.data;
    } else {
      console.error("API returned an error:", response.data.Message);
      return [];
    }
  } catch (error) {
    console.error("Veri Gönderilemedi", error);
    throw error;
  }
};
