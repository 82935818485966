import React, { useState, useRef, useEffect } from "react";
import { RiFacebookCircleFill } from "@remixicon/react";
import { Link } from "react-router-dom";
import { createMarkup } from "../../helpers/utils";
import parse from "html-react-parser";
import { RiArrowDownSLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-up";
import { RiArrowUpDoubleFill } from "@remixicon/react";

import { useCountry } from "../../contexts/CountryContext";
const Footer = () => {
  const [f1IsHidden, setF1IsHidden] = useState(true);
  const [f2IsHidden, setF2IsHidden] = useState(true);
  const [f3IsHidden, setF3IsHidden] = useState(true);
  const [f4IsHidden, setF4IsHidden] = useState(true);
  // Diğer durumları eklemek için aynı şekilde devam edebilirsin.

  const { t } = useTranslation();
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  const countryList = useCountry();
  const currentYer = getCurrentYear();
  // Listeyi açma fonksiyonu, parametre olarak durumu set etmek için bir fonksiyon alacak
  const toggleList = (setIsHidden) => {
    setIsHidden((prevIsHidden) => !prevIsHidden);
  };

  return (
    <div>
      <footer className="bg-color5 pt-20 pb-10">
        <div className="container mx-auto">
          <div className="flex gap-18 fl-x lg:mx-0 mx-10 ">
            <div className="w-full ">
              <h3
                className="text-green font-medium text-hsm mb-5  justify-between flex font-semibold"
                onClick={() => toggleList(setF1IsHidden)}
              >
                {t("dijital_vinyet")}
                <RiArrowDownSLine className="lg:hidden  justify-between" />
              </h3>

              <ul className={f1IsHidden ? "hidden-o" : ""}>
                {countryList.map((item) => (
                  <li key={item.Id}>
                    <a
                      href={"/ulke" + item.LinkURL}
                      className="text-pxss flex items-center gap-3 mb-4  font-light text-green "
                    >
                      <img
                        className="rounded-full w-5 h-5"
                        src={item.ProductCategoryImg}
                      />{" "}
                      {item.Name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full f-link">
              <h3
                className="text-green font-medium text-hsm mb-5 justify-between flex font-semibold"
                onClick={() => toggleList(setF2IsHidden)}
              >
                {t("sozlesmeler")}
                <RiArrowDownSLine className="lg:hidden  justify-between" />
              </h3>

              <ul
                dangerouslySetInnerHTML={{ __html: t("footer_link") }}
                className={f2IsHidden ? "hidden-o" : ""}
              ></ul>
            </div>

            <div className="w-full">
              <h3
                className="text-green font-medium text-hsm mb-5 justify-between flex font-semibold"
                onClick={() => toggleList(setF3IsHidden)}
              >
                {t("hizli_link")}
                <RiArrowDownSLine className="lg:hidden  justify-between" />
              </h3>

              <ul className={f3IsHidden ? "hidden-o" : ""}>
                <li>
                  <Link
                    to="/tolltake-nasil-calisir"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("nasil_calisir")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/vinyet-kontrol"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("vinyet_control")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("faq")}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="w-full">
              <h3
                className="text-green text-hsm mb-5 justify-between flex font-semibold"
                onClick={() => toggleList(setF4IsHidden)}
              >
                {t("platform")}
                <RiArrowDownSLine className="lg:hidden  justify-between" />
              </h3>

              <ul className={f4IsHidden ? "hidden-o" : ""}>
                <li>
                  <Link
                    to="/tolltake-nasil-calisir"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("platform_G")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/login"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("giris_yap")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/register"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("uye_ol")}
                  </Link>
                </li>

                <li>
                  <Link
                    to="/iletisim"
                    className="text-pxss  mb-4 block font-light text-green"
                  >
                    {t("iletisim")}
                  </Link>
                </li>
              </ul>
            </div>

            <div
              dangerouslySetInnerHTML={{ __html: t("social_media_footer") }}
              className="w-full"
            ></div>
          </div>

          <div className="lg:gap-10 gap-0 l lg:mt-10 mt-4 br-t">
            <div className="lg:flex grid  lg:gap-10 gap-0 lg:ml-0 ml-9">
              <p className="text-pxss  mb-4  font-light text-green">
                © TollTake {currentYer} All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>

      <ScrollToTop showUnder={160}>
        <div className="top-ord">
          <span>
            <RiArrowUpDoubleFill />
          </span>
        </div>
      </ScrollToTop>
    </div>
  );
};

export default Footer;
