import React, { useState, useEffect } from "react";
import { Link ,useLocation} from "react-router-dom";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { RiMenuFill } from "@remixicon/react";
import { RiCloseLargeFill } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import { RiArrowRightLine } from "@remixicon/react";

const Menu = inject("AuthStore")(
  observer(({ AuthStore, ...props }) => {
    const [mobilMenu, setmobilMenu] = useState(false);
    const handleClickMenu = () => {
      setmobilMenu(!mobilMenu);
    };
    const { t } = useTranslation();
    const formattedLoginDate = AuthStore.appState.user.LoginDate.replace(
      /(\w+) (\d+)(st|nd|rd|th), (\d+) (\d+):(\d+)/,
      "$1.$2.$4 $5:$6"
    );
    const LoginDate = new Date(formattedLoginDate);
    const formattedDateLogin = LoginDate.toLocaleDateString("tr-TR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    const history = useHistory();
    const logoutHandle = () => {
      history.push("/");
      AuthStore.removeToken();
    };

    const location = useLocation();
    const currentPath = location.pathname;

    return (
      <div className="lg:w-1/4   mb-5 ">
        <div className="flex p-5 justify-between items-center lg:mx-0 mx-3 lg:bg-color5 bg-color11 rounded-lg">
          <div>
            <div className="flex items-center gap-3 ">
              <div className="u-name">
                <span> {AuthStore.appState.user.FirstName.charAt(0)}</span>
              </div>
              <div className="flex justify-between">
                <div className="grid gap-1 lg:pl-0 ">
                  <span className="font-semibold text-hsm text-green">
                    {t("hosgeldin")} <br /> {AuthStore.appState.user.FirstName}{" "}
                    {AuthStore.appState.user.LastName}
                  </span>
                  <span className="text-sxss font-light color-green">
                  {t("son_giris_tarihi")}  {formattedDateLogin && formattedDateLogin}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            {!mobilMenu && (
              <RiMenuFill
                className="lg:hidden inline-block"
                onClick={handleClickMenu}
              />
            )}
            {mobilMenu && <RiCloseLargeFill onClick={handleClickMenu} />}
          </div>
        </div>

        <div className={!mobilMenu ? "lg:block hidden" : "lg:block"}>
          <ul className="bg-green px-5 rounded-lg mt-4 py-7 lg:mx-0 mx-3 list-m">
            <li className="mb-6 relative">
              <Link to="/dash" className="flex justify-between text-white font-semibold text-psm">
                <span>
                  <img
                    className="inline-block mr-4"
                    src="assets/img/home.svg"
                  />{" "}
                 {t("bana_ozel")}
                </span>{" "}

             
                {currentPath === '/dash' && <RiArrowRightLine />}
              </Link>
            </li>
            <li className="mb-6 relative">
              <Link to="/buy-history" className="flex justify-between text-white font-semibold text-psm">
                <span>
                  <img
                    className="inline-block mr-4"
                    src="assets/img/order.svg"
                  />{" "}
                 {t("satin_alma_gecmisi")}
                </span>{" "}

             
                {currentPath === '/buy-history' && <RiArrowRightLine />}
              </Link>
            </li>


            <li className="mb-6 relative">
              <Link to="/invoices" className="flex justify-between text-white font-semibold text-psm">
                <span>
                  <img
                    className="inline-block mr-4 w-5"
                    src="assets/img/invoices.svg"
                  />{" "}
                       {t("faturalarım")}
                </span>{" "}

             
                {currentPath === '/invoices' && <RiArrowRightLine />}
              </Link>
            </li>


            <li className="mb-6 relative">
              <Link to="/vehicles" className="flex justify-between text-white font-semibold text-psm">
                <span>
                  <img
                    className="inline-block mr-4"
                    src="assets/img/car.svg"
                  />{" "}
                  {t("araclarim")}
                </span>{" "}

             
                {currentPath === '/vehicles' && <RiArrowRightLine />}
              </Link>
            </li>
        
            <li className="mb-6 relative">
              <Link  to="/invoices" className="text-white font-semibold text-psm">
                <span>
                  <img className="inline-block mr-4" src="assets/img/krl.svg" />{" "}
                </span>{" "}
                   {t("ihlal_kontrol")}{" "}
              </Link>
            </li>
            <li className="mb-6 relative">
              <Link className="text-white font-semibold text-psm">
                {" "}
                <span>
                  <img
                    className="inline-block mr-4"
                    src="assets/img/cams.svg"
                  />
                </span>{" "}
               {t("indirim_kampanyalar")}{" "}
              </Link>
            </li>


            <li className="mb-6 relative"    onClick={() => logoutHandle()}>
              <a href="#" className="text-white font-semibold text-psm">
           
                <span>
                  <img
                    className="inline-block mr-4"
                    src="assets/img/logout.svg"
                  />
                </span>{" "}
                {t("cikis_yap")}{" "}
              </a>
            </li>
          
          </ul>
        </div>
      </div>
    );
  })
);
export default Menu;
