import React, { useState, useRef, Fragment, useEffect } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { inject, observer } from "mobx-react";

import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { RiArrowDownSLine } from "@remixicon/react";
import { RiUserLine } from "@remixicon/react";
import Home from "../../pages/Homepage";
import { useCountry } from "../../contexts/CountryContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = (props) => {
  const countryList = useCountry();

  const { t } = useTranslation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const submenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setIsSubMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <div className="h-page-c">
      {t("top_page") && (
        <div className="bg-green lg:block md:hidden hidden w-full py-3 font-semibold text-plg rs-hid">
          <div className="animate-marquee whitespace-nowrap">
            <span className="text-light-green block w-full">
              {t("top_page")}
            </span>
          </div>
        </div>
      )}

      <header className="container mx-auto px-5 lg:px-0 flex h-24 items-center justify-between z-[9] relative  ">
        <div className="flex items-center xl:gap-4 lg:gap-5">
          <div className="Logo-nav ">
            <Link to="/">
              <img
                className="size-logo lg:block md:hidden hidden"
                src="/assets/img/tolltake-logo.svg"
                alt="Logo"
              />
              <img
                className="size-logo lg:hidden md:block block"
                src="/assets/img/logo-w.svg"
                alt="Logo"
              />
            </Link>
          </div>

          <nav className="mx-auto " aria-label="Global">
            <Popover.Group className="hidden lg:flex lg:gap-x-8">
              <Popover className="relative">
                <Popover.Button className="flex  text-hmdxp font-semibold  text-green">
                {t("dijital_vinyet")}
                  <ChevronDownIcon
                    className="h-6 w-6 flex-none relative  left-1 text-gray-400"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg ">
                    <div className="p-4">
                      {countryList.map((item) => (
                        <div
                          key={item.Id}
                          className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                        >
                          <div className="flex gap-3">
                            <img
                              className="rounded-full w-5 h-5"
                              src={item.ProductCategoryImg}
                            />
                            <a
                              href={"/ulke" + item.LinkURL}
                              className="block font-semibold text-green text-hmdxp"
                            >
                              {item.Name}
                              <span className="absolute inset-0" />
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover.Panel>
                </Transition>
              </Popover>
              <Link
                to="/tolltake-nasil-calisir"
                className="text-hmdxp font-semibold  text-green"
              >
                 {t("nasil_calisir")}
              </Link>
              <Link
                to="/vinyet-kontrol"
                className="text-hmdxp font-semibold  text-green"
              >
              {t("vinyet_control")}
              </Link>
              {/* <a
                href="#"
                className="text-hmdxp font-semibold  text-green"
              >
                Fiyatlar
              </a> */}
              <Link to="/faq" className="text-hmdxp font-semibold  text-green">
              {t("faq")}
              </Link>
            </Popover.Group>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden "
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-hero-s px-5 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between border-header">
                <a href="#" className="-m-1.5 p-1.5">
                  <img
                    className="size-logo w-auto"
                    src="/assets/img/logo-w.svg"
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    <Disclosure as="div" className="-mx-3">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full items-center justify-between  pl-3 pr-3 px-3 py-2 font-semibold  text-white   text-hmdxp hover:text-orange">
                          {t("dijital_vinyet")}
                            <ChevronDownIcon
                              className={classNames(
                                open ? "rotate-180" : "",
                                "h-5 w-5 flex-none"
                              )}
                              aria-hidden="true"
                            />
                          </Disclosure.Button>

                          <Disclosure.Panel className="mt-2 space-y-2">
                            {[...countryList].map((item) => (
                              <Disclosure.Button
                                key={item.Id}
                                as="a"
                                href={"/ulke" + item.LinkURL}
                                className="flex gap-3 items-center  py-2 pl-6 pr-3  font-light  text-white"
                              >
                                <img
                                  className="rounded-full w-5 h-5"
                                  src={item.ProductCategoryImg}
                                />
                                {item.Name}
                              </Disclosure.Button>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <a
                      href="/tolltake-nasil-calisir"
                      className="-mx-3 block   px-3 py-2 text-hmdxp font-semibold  text-white"
                    >
                      {t("nasil_calisir")}
                    </a>
                    <a
                      href="/vinyet-kontrol"
                      className="-mx-3 block   px-3 py-2 text-hmdxp font-semibold  text-white"
                    >
                     {t("vinyet_control")}
                    </a>
                 
                    <a
                      href="/faq"
                      className="-mx-3 block  px-3 py-2 text-hmdxp font-semibold  text-white"
                    >
                    {t("faq")}
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <div className="right-content-nav">
          <div className="flex xl:gap-9 lg:gap-3 items-center">
            <div className="contact-nav xl:block md:hidden lg:hidden hidden">
              <span className="font-light text-green text-psm">
              {t("yardimci_button")}
              </span>
              <Link to="#" className="flex gap-2 ">
                <img src="/assets/img/phone.svg" alt="phone" />
                <span className="font-semibold text-hmdxp text-green">
                 
                {t("phone")}
                </span>
              </Link>
            </div>

            <div className="lang lg:mr-0 mr-5">
              <span className="gap-2 flex relative items-center">
                <img
                  className="world-size lg:inline-block md:hidden hidden"
                  src="/assets/img/earth.svg"
                />
                <img
                  className="world-size lg:hidden md:inline-block inline-block"
                  src="/assets/img/earth-w.svg"
                />

                <span className="font-medium lg:text-green lg:text-hmdxp text-hmdxp text-white flex items-center gap-1">
                  TR <RiArrowDownSLine size={26} className="arrow-down-i  " />
                </span>
              </span>
            </div>

            <div className="login-nav">
              {props.AuthStore.isLoggedIn ? (
                <Link
                  to="/dash"
                  className="inline-block text-psm bg-orange text-white lg:px-7 lg:py-3 p-1 font-medium rounded-full hover:bg-green"
                >
                  <span className="w-30 lg:block md:hidden hidden">
                  {t("hesabim")}{" "}
                  </span>
                  <span className="w-30 lg:hidden md:block block">
                    {" "}
                    <RiUserLine />
                  </span>
                </Link>
              ) : (
                <Link
                  to="/login"
                  className="inline-block text-psm bg-orange text-white lg:px-7 lg:py-3 p-1 font-medium rounded-full hover:bg-green"
                >
                  <span className="w-30 lg:block md:hidden hidden">
                    {t("auth")} {" "}
                  </span>
                  <span className="w-30 lg:hidden md:block block">
                    {" "}
                    <RiUserLine />
                  </span>
                </Link>
              )}
            </div>
            <div className="flex lg:hidden ml-4">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center  p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon
                  className=" text-white w-[40px] h-[40px]"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default inject("AuthStore")(observer(Header));
