import React, { useRef, useState, Fragment, useEffect } from "react";
import { defaultPurchaseData } from "../../contexts/ResetData";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { usePurchase } from "../../contexts/PurchaseContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function StepFour({ handlePrev, handleNext }) {
  const { t } = useTranslation();

  const { purchaseData, addToCart,saveToCart, cartItems } = usePurchase();
  const { updatePurchaseData } = usePurchase();
  function ListSelect() {
    const [countryS, setcountryS] = useState([]);
    const [selectedCountry, setselectedCountry] = useState(null);

    const [selectedName, setselectedName] = useState(null);

    const [selectedFlag, setselectedFlag] = useState(null);
    const BASE_URL = process.env.REACT_APP_API_URL;
   

   

    useEffect(() => {
      axios
        .get(`${BASE_URL}/v1/LicenseCountry/GetList?LanguageId=1`)
        .then((response) => {
          if (response.data.Success) {
            setcountryS(response.data.Data);
          }
        })
        .catch((error) => {
          console.error("Veri Gönderilemedi", error);
        });
    }, []);

    return (
      <div>
        <Listbox
          value={selectedCountry}
          onChange={(selected) => {
            setselectedCountry(selected);
            setselectedName(selected.Name);
            setselectedFlag(selected.DataCode);

            updatePurchaseData("lisenceCountry", selected.Id);
            updatePurchaseData("lisenceCountryName", selected.Name);
            updatePurchaseData("lisenceCountryImg", selected.DataCode);
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="mb-2 text-left block font-medium text-psm text-white">
                {t('aracin_t_edildigi_ulke')}
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-color8 px-5 py-5 text-left text-gray-900 border border-color4">
                  <span className="flex items-center">
                    {purchaseData.lisenceCountryImg && (
                      <img
                        className="w-5 h-5 rounded-full inline-block mr-2"
                        src={purchaseData.lisenceCountryImg}
                      />
                    )}
                    <span className="block truncate text-green">
                      {purchaseData.lisenceCountryName
                        ? purchaseData.lisenceCountryName
                        : t('ulke_seciniz')}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={React.Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options className="hov-a absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {countryS &&
                      countryS.map((vehicle) => (
                        <Listbox.Option
                          key={vehicle.Id}
                          className={({ active }) =>
                            classNames(
                              active ? "bg-indigo-600" : "text-gray-900",
                              "cursor-default select-none py-2 pr-9"
                            )
                          }
                          value={vehicle}
                        >
                          <div className="flex items-center pl-4">
                            <img
                              className="w-5 h-5 rounded-full"
                              src={vehicle.DataCode}
                            />
                            <span
                              className={classNames(
                                selectedCountry === vehicle
                                  ? "font-semibold"
                                  : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {vehicle.Name}
                            </span>
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    );
  }
  const resetPurchaseData = () => {
    Object.entries(defaultPurchaseData).forEach(([key, value]) => {
      updatePurchaseData(key, value);
    });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handlelisencePlateClick = (lisencePlate) => {
    updatePurchaseData("lisencePlate", lisencePlate);
  };

  if (purchaseData.startDate == "") {
    handlePrev();
  }
  const NextButton = () => {
    if (
      purchaseData.lisencePlate != "" &&
      purchaseData.lisenceCountryName != ""
    ) {
      addToCart({
        ...purchaseData,
        id: Date.now(),
      });
     

  

      handleNext();
      resetPurchaseData();
    
    }
  };
  return (
    <div>
      <h2 className="text-color7 text-hmdx2 font-light mb-1">
       {t('plakanızı_giriniz')}
      </h2>
      <p className="text-white font-light text-psm">
        {t('plakanızı_giriniz_text')}
     
      </p>
      <div className="select-lab mt-6">
        <ListSelect />
      </div>
      <div className="select-lab mt-8">
        <label className="mb-3 block font-medium text-psm text-white ">
        {t('plakaniz')}
        </label>

        <div className="plk">
          <div className="flex rounded-lg">
            <label className="plk-2">
              {purchaseData.lisenceCountryName
                ? purchaseData.lisenceCountryName.charAt(0).toUpperCase() +
                  purchaseData.lisenceCountryName.charAt(1).toUpperCase()
                : "TR"}
            </label>

            <input
              value={purchaseData.lisencePlate}
              onChange={(e) => handlelisencePlateClick(e.target.value)}
              className="pt-7 pl-7 pb-2 w-full rounded-r-lg "
              placeholder="34 AA 1252"
            />
          </div>

          <button
            onClick={() => NextButton()}
            className="bg-orange py-5 rounded-md mt-7 text-white font-semibold text-hsm w-full"
          >
             {t('devam_et')}
          </button>
        </div>
      </div>
    </div>
  );
}
