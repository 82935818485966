import React, { useRef, useState, useEffect } from "react";
import "./dash.css";
import { Link } from "react-router-dom";
import { RiSearch2Line } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { RiArrowDropDownLine } from "@remixicon/react";

import Menu from "../../component/dashboard/Menu";

import { PencilIcon } from "@heroicons/react/24/solid";
import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Avatar,
  IconButton,
  Tooltip,
  Input,
} from "@material-tailwind/react";

const Invoice = inject("AuthStore")(
  observer(({ handlePrev, removeToken, handleNext, AuthStore, ...props }) => {
    const date = [
      { id: 1, Name: "2024" },
      { id: 2, Name: "2023" },
      { id: 3, Name: "2022" },
      { id: 4, Name: "2021" },
      { id: 5, Name: "2020" },
    ];

    const TABLE_HEAD = [
      "Invoice Number",
      "Date of Purchase",
      "Number of e-vignettes",
      "Total amounts",
      "",
      "",
    ];
    const [openDetailIndex, setOpenDetailIndex] = useState(null);

    const handleRowClick = (index) => {
      setOpenDetailIndex(openDetailIndex === index ? null : index);
    };
    const TABLE_ROWS = [
      {
        name: "311312",
        amount: "02.06.2024 13:03:25",
        date: "1 db",
        status: "6.400 €",
        account: "http://localhost:3000/",
        accountNumber: "1234",
        expiry: "",
      },
      {
        name: "311312",
        amount: "02.06.2024 13:03:25",
        date: "1 db",
        status: "6.400 €",
        account: "http://localhost:3000/",
        accountNumber: "1234",
        expiry: "",
      },
      {
        name: "311312",
        amount: "02.06.2024 13:03:25",
        date: "1 db",
        status: "6.400 €",
        account: "http://localhost:3000/",
        accountNumber: "1234",
        expiry: "",
      },
      {
        name: "311312",
        amount: "02.06.2024 13:03:25",
        date: "1 db",
        status: "6.400 €",
        account: "http://localhost:3000/",
        accountNumber: "1234",
        expiry: "",
      },
      {
        name: "311312",
        amount: "02.06.2024 13:03:25",
        date: "1 db",
        status: "6.400 €",
        account: "http://localhost:3000/",
        accountNumber: "1234",
        expiry: "",
      },
    ];
    const { t } = useTranslation();
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const BASE_URL = process.env.REACT_APP_API_URL;
    const [Vignette, setVignetteID] = useState([]);

    useEffect(() => {
      axios
        .get(`${BASE_URL}/v1/Vignette/GetCustomerVignettes`, {
          headers: {
            Authorization: `Bearer ${AuthStore.appState.token}`,
          },
        })
        .then((response) => {
          if (response.data.Success) {
            setVignetteID(response.data.Data);
            // Vignette değeri yerine response.data.Data kullanılmalı
          }
        })
        .catch((error) => {
          console.error("Veri Gönderilemedi", error);
        });
    }, []);

    function classNames(...classes) {
      return classes.filter(String).join(" ");
    }
    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    return (
      <div className="bg-dash">
        <div className="lg:hidden block top-head-page ">
          <h2 className="text-color7 lg:text-left text-center lg:text-lgheads text-hmdx2 font-light">
            <div dangerouslySetInnerHTML={{ __html: t("login_head_1") }} />
          </h2>
          <p className="text-white lg:text-left text-center lg:text-hsm text-hxss font-light lg:max-w-md mb-0">
            {t("login_text_1")}
          </p>
        </div>

        <div className="container mx-auto lg:mt-10 mt-0">
          <div className="lg:flex grid lg:gap-10 gap-0 rounded-lg lg:pt-0 pt-5 rs-mt lg:bg-colorb bg-color8">
            <Menu />
            <div className="lg:w-3/4">
              <div className="lg:bg-white  mb-10 bg-color8 rounded-lg lg:px-10 w-form-incv  px-2 py-1 ">
                <div className="invoice-form">
                  <div className="flex gap-5">
                    <div className="form-input">
                      <label>Search by plate</label>
                      <input className="" placeholder="Plate"></input>
                    </div>
                    <div className="form-input">
                      <Listbox
                        value={selectedVehicle}
                        onChange={setSelectedVehicle}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label>Years</Listbox.Label>
                            <div className="relative  mx-w-home">
                              <Listbox.Button className="bt-dash">
                                <span className="flex items-center">
                                  <span className="block truncate">
                                    {selectedVehicle
                                      ? selectedVehicle.Name
                                      : "----"}
                                  </span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={React.Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Listbox.Options className="hov-a absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {date &&
                                    date.map((vehicle) => (
                                      <Listbox.Option
                                        key={vehicle.Id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? "" : "text-gray-900",
                                            "cursor-default select-none py-2 pr-9"
                                          )
                                        }
                                        value={vehicle}
                                      >
                                        {({ selectedVehicle, active }) => (
                                          <div className="flex items-center">
                                            <span
                                              className={classNames(
                                                selectedVehicle
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "ml-3 block truncate"
                                              )}
                                            >
                                              {vehicle.Name}
                                            </span>
                                          </div>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="form-input submit-form-dash">
                      <button>
                        <RiSearch2Line />
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div className="invoice-tab mb-10">
                  <Card className="h-full w-full">
                    <CardBody className="overflow-scroll px-0">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD.map((head) => (
                              <th
                                key={head}
                                className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {TABLE_ROWS.map(
                            ({ name, amount, date, status }, index) => {
                              const isLast = index === TABLE_ROWS.length - 1;
                              const classes = isLast
                                ? "p-3"
                                : "p-3   border-b border-blue-gray-50";

                              return (
                                <React.Fragment key={name}>
                                  <tr
                                    onClick={() => handleRowClick(index)}
                                    className="cursor-pointer"
                                  >
                                    <td className={classes}>
                                      <div className="flex items-center gap-3">
                                        {name}
                                      </div>
                                    </td>
                                    <td className={classes}>
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {amount}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                      >
                                        {date}
                                      </Typography>
                                    </td>
                                    <td className={classes}>
                                      <div className="w-max">
                                        <Chip
                                          size="sm"
                                          variant="ghost"
                                          value={status}
                                        />
                                      </div>
                                    </td>
                                    <td className={classes}>
                                      <a className="down-link">
                                        {" "}
                                        Download Invoice
                                      </a>
                                    </td>
                                    <td className={classes}>
                                      <Tooltip content="Vignette Detail">
                                        <IconButton variant="text">
                                          <RiArrowDropDownLine className="h-7 w-7" />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                  </tr>
                                  {openDetailIndex === index && (
                                    <tr>
                                      <td
                                        colSpan="6"
                                        className="p-3 bg-gray-50"
                                      >
                                        <div className="detail_bottom">
                                          <div>
                                            <span>Shopping Cart ID</span>
                                            <b>213123LDSFD</b>
                                          </div>
                                          <div>
                                            <span>Registration Number</span>
                                            <b>34TR136</b>
                                          </div>
                                          <div>
                                            <span>Type Of E-Vignette</span>
                                            <b>10 Day</b>
                                          </div>
                                          <div>
                                            <span>Start Validity</span>
                                            <b>06.06.2024</b>
                                          </div>
                                          <div>
                                            <span>End Validity</span>
                                            <b>16.06.2024</b>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter className="flex items-center justify-between ">
                      <Button variant="outlined" size="sm">
                        Previous
                      </Button>
                      <div className="flex items-center gap-2">
                        <IconButton variant="outlined" size="sm">
                          1
                        </IconButton>
                        <IconButton variant="text" size="sm">
                          2
                        </IconButton>
                        <IconButton variant="text" size="sm">
                          3
                        </IconButton>
                        <IconButton variant="text" size="sm">
                          ...
                        </IconButton>
                        <IconButton variant="text" size="sm">
                          8
                        </IconButton>
                        <IconButton variant="text" size="sm">
                          9
                        </IconButton>
                        <IconButton variant="text" size="sm">
                          10
                        </IconButton>
                      </div>
                      <Button variant="outlined" size="sm">
                        Next
                      </Button>
                    </CardFooter>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default Invoice;
