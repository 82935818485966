import React, { useState, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Alert } from "reactstrap";
import { RiArrowDownSLine } from "@remixicon/react";
import { RiEyeLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";

import { RiEyeOffLine } from "@remixicon/react";
import { inject, observer } from "mobx-react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
} from "../../component/Component";
import { RiCloseLargeFill } from "@remixicon/react";
const Register = (props) => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [isCheckedContract, setIsCheckedCheckedContract] = useState(
    false,
    null
  );
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  const [isMessage, setisMessage] = useState(false);

  const [pageContent, setPageContent] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_URL;

  const handleCheckboxChange = (index, Id) => {
    setIsCheckedCheckedContract(index);

    axios
      .get(`${BASE_URL}/v1/Page/Get`, {
        params: {
          DataId: Id,
          LanguageId: 1,
        },
      })
      .then((response) => {
        const safeHTML = DOMPurify.sanitize(response.data.PageText);
        response.data.PageText = safeHTML;
        setPageContent(response.data);
      })
      .catch((error) => {
        console.error("Sayfa içeriği yüklenemedi!", error);
      });
  };
  const handleChange = (event) => {
    setIsChecked(event.target.checked);

    if (!isChecked) {
      setisMessage(false);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.AuthStore.appState != null) {
      if (props.AuthStore.appState.isLoggedIn) {
        return props.history.push("/");
      }
    }
  });

  const onFormSubmit = (formData) => {
    if (isChecked) {
      if (
        formData.name != "" &&
        formData.last_name != "" &&
        formData.passcode != "" &&
        formData.passcode_confirm != "" &&
        formData.email != ""
      ) {
        const postData = {
          FirstName: formData.name,
          LastName: formData.last_name,
          Email: formData.email,
          Password: formData.passcode,
          IsTerm: true,
          IsContract: true,
        };

        axios
          .post(
            `https://api.tolltake.com/v1/Customer/Register?LanguageId=1`,
            postData
          )
          .then((response) => {
            if (response.data.Success) {
              const { DataCode: token } = response.data.Data;

              const appState = {
                isLoggedIn: true,
                token,
                user: response.data.Data,
              };
              props.AuthStore.saveToken(appState);
              setTimeout(() => {
                window.history.pushState(
                  `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
                  "register",
                  `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
                );
                window.location.reload();
              }, 1000);
            } else {
              setTimeout(() => {
                setError(response.data.message);
                setLoading(false);
              }, 1000);
            }
          })
          .catch((error) => {
            if (error.response.data.Success != true) {
              setTimeout(() => {
                setError(error.response.data.Message);
                setLoading(false);
              }, 1000);
            } else {
            }
          });
      } else {
        setTimeout(() => {
          setError(t('giris_bilgileriniz_yanlis'));
          setLoading(false);
        }, 1000);
      }
      setLoading(true);
    } else {
      setisMessage(true);
    }
  };

  return (
    <>
      <div className="h-screen">
        <div className="xl:flex lg:flex grid h-full">
          <div className="left-i  relative bg-color8 w-full flex items-center ">
            <div className="w-full lg:mx-24 mx-5 lg:mt-0 mt-2 ">
              <div className="lg:flex items-center mb-20 md:hidden hidden">
                <div className="w-full">
                  <Link to="/">
                    <img
                      className="w-64 relative left-[-50px] "
                      src="/assets/img/tolltake-logo.svg"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="w-full">
                  <div className="float-right">
                    <div className="font-medium lg:text-green   text-hmdxp  flex items-center gap-1">
                      <img
                        width={24}
                        className="inline-block mr-1"
                        src="/assets/img/earth.svg"
                      />
                      Turkish
                      <RiArrowDownSLine size={24} className="arrow-down-i  " />
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="text-green  lg:text-left text-center text-hmdx2   font-medium  mb-6">
                {t("kayit_ol")}
              </h2>

              {errorVal && (
                <div className="mb-3 form-auth">
                  <Alert color="danger" className="alert-icon login-alert">
                    <Icon name="alert-circle" /> {errorVal}
                  </Alert>
                </div>
              )}
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                  <div className="md:flex grid gap-3">
                    <div className="lg:w-1/2  w-full ">
                      <div className="grid relative  mb-3">
                        <label className="text-green text-psm">
                          {t("adiniz")}
                        </label>
                        <input
                          type="text"
                          id="default-01"
                          {...register("name", {
                            required: t("bu_alan_gereklidir"),
                          })}
                          className="px-5 py-3 border  w-full border-solid border-green-900 rounded-lg"
                          placeholder={t("adiniz")}
                        />
                        {errors.name && (
                          <span className="invalid absolute bottom-[-19px]">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full">
                      <div className="grid relative mb-3">
                        <label className="text-green text-psm">
                          {t("soyadiniz")}
                        </label>
                        <input
                          type="text"
                          id="default-01"
                          {...register("last_name", {
                            required: t("bu_alan_gereklidir"),
                          })}
                          className="px-5 py-3 border border-solid border-green-900 rounded-lg"
                          placeholder={t("soyadiniz")}
                        />
                        {errors.last_name && (
                          <span className="invalid absolute bottom-[-19px]">
                            {errors.last_name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="gap-3 mt-3 ">
                    <div className="w-full ">
                      <div className="grid relative mb-3">
                        <label className="text-green text-psm">
                          {t("Email")}
                        </label>
                        <input
                          type="text"
                          id="default-01"
                          {...register("email", {
                            required: t("bu_alan_gereklidir"),
                          })}
                          className="px-5 py-3 border border-solid border-green-900 rounded-lg"
                          placeholder={t("Email")}
                        />
                        {errors.email && (
                          <span className="invalid absolute bottom-[-19px]">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="md:flex mt-6 grid gap-3">
                    <div className="lg:w-1/2  w-full mb-3">
                      <div className="grid relative">
                        <label className="text-green text-psm relative">
                          {t("parola")}
                        </label>

                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState(!passState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch absolute right-3 bottom-3.5 ${
                            passState ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <RiEyeLine
                            name="eye "
                            className=" r-0 passcode-icon icon-show"
                          ></RiEyeLine>

                          <RiEyeOffLine
                            name="eye-off"
                            className="passcode-icon icon-hide"
                          ></RiEyeOffLine>
                        </a>
                        <input
                          type={passState ? "text" : "password"}
                          id="password"
                          {...register("passcode", {
                            required: t("bu_alan_gereklidir"),
                          })}
                          placeholder={t("parolar_giriniz")}
                          className={`px-5 py-3 border border-solid border-green-900 rounded-lg ${
                            passState ? "is-hidden" : "is-shown"
                          }`}
                        />
                        {errors.passcode && (
                          <span className="invalid absolute bottom-[-19px]">
                            {errors.passcode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="lg:w-1/2 w-full">
                      <div className="grid relative mb-3">
                        <label className="text-green text-psm">
                          {t("parola_g")}
                        </label>

                        <a
                          href="#password"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setPassState(!passState);
                          }}
                          className={`form-icon lg form-icon-right passcode-switch absolute right-3 bottom-3.5 ${
                            passState ? "is-hidden" : "is-shown"
                          }`}
                        >
                          <RiEyeLine
                            name="eye"
                            className=" r-0 passcode-icon icon-show"
                          ></RiEyeLine>

                          <RiEyeOffLine
                            name="eye-off"
                            className="passcode-icon icon-hide"
                          ></RiEyeOffLine>
                        </a>
                        <input
                          type={passState ? "text" : "password"}
                          id="password"
                          {...register("passcode_confirm", {
                            required: t("bu_alan_gereklidir"),
                            validate: (val: string | undefined) => {
                              if (!val) {
                                return t("parola_err");
                              }
                              if (watch("passcode") !== val) {
                                return t("parola_err_2");
                              }
                            },
                          })}
                          placeholder={t("parolar_giriniz")}
                          className={`px-5 py-3 border border-solid border-green-900 rounded-lg ${
                            passState ? "is-hidden" : "is-shown"
                          }`}
                        />
                        {errors.passcode_confirm && (
                          <span className="invalid absolute bottom-[-19px]">
                            {errors.passcode_confirm.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex mt-5 items-center">
                    <input
                      id="link-checkbox"
                      type="checkbox"
                      value="1"
                      checked={isChecked}
                      onChange={handleChange}
                      className="w-4 h-4 text-blue-600 relative bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />

                    <label
                      htmlFor="link-checkbox"
                      className="ms-2 text-gray-900 text-dark text-psm relative font-light"
                    >
                      <a
                        href="#"
                        className="font-bold underline"
                        onClick={() => handleCheckboxChange(true, 35)}
                      >
                        {t("uyelik_sozlesmesi")},{" "}
                      </a>{" "}
                      <a
                        href="#"
                        className="font-bold underline"
                        onClick={() => handleCheckboxChange(true, 36)}
                      >
                        {t("elk_izin")}{" "}
                      </a>
                      {t("ve")}{" "}
                      <a
                        href="#"
                        className="font-bold underline"
                        onClick={() => handleCheckboxChange(true, 37)}
                      >
                        {t("kvkk")}{" "}
                      </a>{" "}
                      {t("sozlesme_devam")}
                    </label>
                  </div>
                  {isMessage && (
                    <div className="message-i">{t("sozlesme_onay")}</div>
                  )}
                </div>
                <div className="b-inp">
                  <Button
                    className="shadow-md text-hsm bg-orange text-white w-full mt-5  mb-5 py-3 rounded-lg"
                    type="submit"
                  >
                    {loading ? t("kayit_yapiliyor") : t("kayit_ol")}
                  </Button>
                </div>

                <div className="text-center text-green text-sxss lg:mb-0 mb-10">
                  <Link to="/login" className="font-light">
                    {t("zaten_uye_misiniz")}
                    <span className="underline font-bold">
                      {t("giris_yap")}
                    </span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>

          <div className="right-i lg:pb-0 pb-8 relative w-full  lg:order-last order-[-1]   bg-login">
            <div className="lg:hidden px-5 mt-4 items-center  md:flex flex">
              <div className="w-full">
                <Link to="/">
                  <img
                    className="w-46 relative "
                    src="/assets/img/logo-w.svg"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="w-full">
                <div className="float-right">
                  <div className="font-medium text-white   text-sxss  flex items-center gap-1">
                    <img
                      width={24}
                      className="inline-block mr-1"
                      src="/assets/img/earth-w.svg"
                    />
                    TR
                    <RiArrowDownSLine size={24} className="arrow-down-i  " />
                  </div>
                </div>
              </div>
            </div>

            <div className="g-login h-full">
              <div className="px-16 lg:pt-16 pt-5">
                <h2
                  dangerouslySetInnerHTML={{ __html: t("login_head_1") }}
                  className="text-color7 lg:text-left text-center lg:text-hlg2 text-hmdx2 font-light"
                ></h2>

                <p className="text-white lg:text-left text-center lg:text-hmd text-psm font-light lg:max-w-md mb-0">
                  {t("login_text_1")}
                </p>
                <img className="w-80 mg-a" src="assets/img/credit.svg" />
              </div>
              <div className="relative lg:block hidden">
                <img
                  className="absolute h-full  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bf-image"
                  src="assets/img/women.png"
                />
              </div>
            </div>
          </div>
        </div>

        {isCheckedContract && pageContent && (
          <div>
            <div className="popup-contract">
              <div className="container">
                <div>
                  <div
                    className="close-contract"
                    onClick={() => handleCheckboxChange(false)}
                  >
                    <RiCloseLargeFill />
                  </div>
                </div>
                <div className="content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageContent.Data.PageText,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="overlay"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default inject("AuthStore")(observer(Register));
