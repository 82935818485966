import React, { useState, Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/pagination";
import SEO from "../../component/content/Seo";

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="contact-page">
              <SEO
    title="Bize Ulaşın - TollTake"
    description="Her türlü soru ve talepleriniz için TollTake ile iletişime geçin. Müşteri hizmetlerimiz 7/24 hizmetinizde olup, güvenli ve kolay yolculuk deneyimi için buradayız."
    author="TollTake"
    image="https://www.tolltake.com/assets/img/tolltake-logo.svg"
    url="https://www.tolltake.com/iletisim"
  />
      <div className="container mx-auto">
        <div className="head-cont">
          <h1> {t("iletisim_page")}</h1>
          <p>
             {t("iletisim_page_text")}
          </p>
        </div>

        <div className="contact-f">
          <div className="bg-1-f">
            <img className="one-img-f" src="assets/img/map.svg" />
            <h2>{t("turkey")}</h2>
            <p>{t("adres")}</p>
            <ul>
              <li>
                <a href="tel:{t('turkey')}"><img src="assets/img/phone_1.svg" />  {t("phone")}</a>
              </li>
              <li>
                <a href="mailto:{t('mail')"><img src="assets/img/mail_1.svg" />    {t("mail")}</a>
              </li>
            </ul>
          </div>
          <div className="bg-2-f">
            <img src="assets/img/support-svgrepo-com 1.svg" />
            <h2>{t("destek_formu")}</h2>
            <p>{t("destek_formu_text")}</p>
            <form>
              <div className="form-o">
                <label>{t("adiniz_soyadiniz")}</label>
                <input placeholder={t("adiniz_soyadiniz_g")} />
              </div>
              <div className="form-o">
                <label>{t("e_mail_adresiniz")}</label>
                <input placeholder={t('e_mail_adresiniz_g')} />
              </div>
              <div className="form-o">
                <label>{t("mesajiniz")}</label>
                <textarea placeholder={t("mesajiniz_g")}></textarea>
              </div>
              <button>{t("gonder")}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
