import { RiCloseLargeFill } from "@remixicon/react";

export const ContractPopup = ({ pageContent, handleCheckboxChange }) => (
    <div>
        <div className="popup-contract">
            <div className="container">
                <div>
                    <div className="close-contract" onClick={() => handleCheckboxChange(false)}>
                        <RiCloseLargeFill />
                    </div>
                </div>
                <div className="content">
                    <div dangerouslySetInnerHTML={{ __html: pageContent.Data.PageText }} />
                </div>
            </div>
        </div>
        <div className="overlay"></div>
    </div>
);