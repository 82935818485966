import React  from "react";


const Loading = () => {


  return (
    <div>
      <div className="container mx-auto">
        <ul>
          <li className="card-loader">&nbsp;</li>

          <li className="card-loader">&nbsp;</li>

          <li className="card-loader">&nbsp;</li>

          <li className="card-loader">&nbsp;</li>

          <li className="card-loader">&nbsp;</li>
        </ul>
      </div>
    </div>
  );
};

export default Loading;
