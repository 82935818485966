import { RiMailFill, RiUser3Fill } from "@remixicon/react";

export const UserForm = ({ emailForm, setEmailForm, nameForm, setNameForm, surnameForm, setSurnameForm }) => (
    <div className="item-o bg-color8 rounded-lg p-4 mb-3">
        <div className="items-center">
            <div className="rd-inp rounded-lg">
                <div className="font-medium text-psm text-green inline-block mb-2 req-form">E-Makbuz ve geçerlilik bildirimi için e-posta <span>(*Zorunlu)</span></div>
                <div className="relative mb-3">
                    <RiMailFill className="absolute w-5 text-orange top-3 left-3" />
                    <input value={emailForm} onChange={(e) => setEmailForm(e.target.value)} className="px-10 py-3 border border-solid border-teal-900 rounded-lg w-full" placeholder="E-posta" type="email" />
                </div>
                <div className="flex gap-5">
                    <div className="w-1/2">
                        <div className="font-medium text-psm text-green inline-block mb-2 req-form">Adınız <span>(*Zorunlu)</span></div>
                        <div className="relative">
                            <RiUser3Fill className="absolute w-5 text-orange top-3 left-3" />
                            <input value={nameForm} onChange={(e) => setNameForm(e.target.value)} className="px-10 py-3 border border-solid border-teal-900 rounded-lg w-full" placeholder="Adınız" type="text" />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="font-medium text-psm text-green inline-block mb-2 req-form">Soyadınız <span>(*Zorunlu)</span></div>
                        <div className="relative">
                            <RiUser3Fill className="absolute w-5 text-orange top-3 left-3" />
                            <input value={surnameForm} onChange={(e) => setSurnameForm(e.target.value)} className="px-10 py-3 border border-solid border-teal-900 rounded-lg w-full" placeholder="Soyadınız" type="text" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
