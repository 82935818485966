// src/contexts/LocalizationContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const LocalizationContext = createContext();
function createMarkup(htmlContent) {
  return { __html: htmlContent };
}

export const LocalizationProvider = ({ children }) => {
  const [localization, setLocalization] = useState({});

  useEffect(() => {
    
    axios.get('https://api.tolltake.com/v1/Localization/Get?LanguageCode=tr-TR')
      .then(response => {
        const loadedLocalization = response.data.Data.reduce((obj, item) => {
          obj[item.name] = item.value;
          return obj;
        }, {});

        setLocalization(loadedLocalization);
      })
      .catch(error => {
        console.error('Localization data could not be loaded', error);
      });
  }, []);

  return (
    <LocalizationContext.Provider value={localization}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
