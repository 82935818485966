import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import BasketStore from '../Store/BasketStore';

const PurchaseContext = createContext();

export const PurchaseProvider = ({ children }) => {
    // Vinyet ve ödeme bilgileri için state
    const [purchaseData, setPurchaseData] = useState({
        country: '',
        countryName: 'Bulgaristan',
        countryImg: '',
        vehicleType: 1,
        vehicleTypeImg: '',
        vehicleTypeName: '',
        duration: -1,
        durationName: '',
        startDate: '',
        finishDate: '',
        lisenceCountry: 1,
        lisenceCountryName: '',
        lisenceCountryImg: '',
        lisencePlate: '',
        totalPrice: 0,
        vignette: '',
        CurrencyId: '',
        CurrencyName: '',
        discountPrice: 0,
        couponCode: ''
    });

    // Sepet işlevleri ve verileri için ayrı bir state
    const [cartItems, setCartItems] = useState([]);


    const addToCart = (newItem) => {
        setCartItems(currentItems => [...currentItems, newItem]);

        updateTotalPrice();
     
    };

    const addToCartBulk = (newItems) => {
        setCartItems(currentItems => [...currentItems, ...newItems]);

        updateTotalPrice();
    };

    const removeFromCart = (vinyetId) => {
        const updatedCartItems = cartItems.filter(v => v.id !== vinyetId);
        setCartItems(updatedCartItems);
      
        updateTotalPrice();
        
    };

    useEffect(() => {
        const loadCartItems = async () => {
            const basket = await BasketStore.getBasket();
          
            if (basket) {
                setCartItems(basket);
            }
        };

        loadCartItems();
    }, []);

    useEffect(() => {
   

        BasketStore.saveBasket(cartItems);
    }, [cartItems]);

    // useEffect(() => {
    //     alert("tesr")
    //    alert(cartItems);
    //     BasketStore.saveBasket(cartItems);

        
    // }, [cartItems]); 

    const getCartItems =() => {
        var basket =  BasketStore.getBasket(); 
    
        if(basket)
        {
               setCartItems(basket);
         }

      };

 

    const clearCart = () => {
        setCartItems([]);
        updateTotalPrice();
        BasketStore.clearCart();
    };

    const updateTotalPrice = () => {
        const newTotal = cartItems.reduce((total, item) => total + item.price, 0);
        setPurchaseData(prev => ({ ...prev, totalPrice: newTotal }));
    };

    const updatePurchaseData = (field, value) => {
        setPurchaseData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    return (
        <PurchaseContext.Provider value={{
            purchaseData,
            updatePurchaseData,
            getCartItems,
            addToCart,
            cartItems,
            addToCartBulk,
            removeFromCart,
            clearCart
        }}>
            {children}
        </PurchaseContext.Provider>
    );
};

export const usePurchase = () => useContext(PurchaseContext);
