import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from './layout/Footer';
import Header from './layout/Header';
import Home from './pages/Homepage';
import Login from './pages/login/LoginPage';
import Register from './pages/auth/Register';
import Dash from './pages/dashboard/dash';
import BuyHistory from './pages/dashboard/buy-history';
import Invoice from './pages/dashboard/Invoice';
import Vehicles from './pages/dashboard/vehicles';

import ProtectedRoute from './routes/ProtectedRoute';
import DynamicPageLoader from './pages/DynamicPageLoader';
import DynamicCountryLoader from './pages/DynamicCountryLoader';

import Index from './pages/how-does-work/Index';
import Vcontrol from './pages/vinyet-control/Vcontrol';

import Faq from './pages/faq/Index';

import BuyPage from './pages/buy/BuyPage';
import Forgot from './pages/auth/Forgot';
import NewForgot from './pages/auth/NewForgot';

import CountryComponent from './pages/content/CountryComponent';
import Contact from './pages/contact/Contact';
import Analytics from './Analytics';

const Routes = () => {
  return (
    <div>
      <Analytics />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/resetpassword" component={NewForgot} />
        <Route path="/buy" component={BuyPage} />
        <Route>
          <Header />
          <Route path="/tolltake-nasil-calisir" component={Index} />
          <Route path="/country" component={CountryComponent} />
          <Route path="/iletisim" component={Contact} />
          <Route path="/vinyet-kontrol" component={Vcontrol} />
          <Route path="/faq" component={Faq} />
          <Route exact path="/" component={Home} />
          <ProtectedRoute path="/dash" component={Dash} />
          <ProtectedRoute path="/buy-history" component={BuyHistory} />
          <ProtectedRoute path="/invoices" component={Invoice} />
          <ProtectedRoute path="/vehicles" component={Vehicles} />
          <Route path="/ulke/:slug" render={(props) => <DynamicCountryLoader key={props.match.params.slug} {...props} />} />
          <Route path="/:slug" render={(props) => <DynamicPageLoader key={props.match.params.slug} {...props} />} />
          <Footer />
        </Route>
      </Switch>
    </div>
  );
};

export default Routes;
