export const TotalPrice = ({ totalPriceBuy }) => (
    <div className="item-o bg-color8 rounded-lg p-4 mb-3">
        <div className="flex items-center justify-between">
            <div className="grid gap-0">
                <span className="text-green inline-block text-psm rounded-xl font-bold">Toplam :</span>
                <span className="font-light text-psm text-green inline-block">Vergiler Dahil</span>
            </div>
            <div className="font-semibold">{totalPriceBuy.toFixed(2)} Euro</div>
        </div>
    </div>
);