import React, { useRef, useState, Fragment, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import { tr } from "date-fns/locale";
import { useTranslation } from "react-i18next";

import { isFriday, isWeekend ,parse} from "date-fns";
import "react-day-picker/dist/style.css";
import { usePurchase } from "../../contexts/PurchaseContext";
import {
  addDays,
  format,
  startOfDay,
  isWithinInterval,
  isBefore,
} from "date-fns";
export default function StepThree({ handlePrev, handleNext }) {
  const { t } = useTranslation();

  const [selectedDay, setSelectedDay] = useState(null);
  const { updatePurchaseData,purchaseData } = usePurchase();


  
  function parseCustomDate(dateStr) {
    return parse(dateStr, 'd MMMM yyyy HH:mm', new Date(), { locale: tr });
}

  useEffect(() => {
    // if (beforePurchaseData && beforePurchaseData.startDate) {

    //   const date = parseCustomDate(beforePurchaseData.startDate);
    //   if (!isNaN(date)) {
    //     setSelectedDay(date);
    //   } else {
    //     console.error("Geçersiz tarih değeri:", beforePurchaseData.startDate);
    //   }
    // }

    if (purchaseData && purchaseData.startDate) {
      console.log(purchaseData);
      const date = parseCustomDate(purchaseData.startDate);
      if (!isNaN(date)) {
        setSelectedDay(date);
      } else {
        console.error("Geçersiz tarih değeri:", purchaseData.startDate);
      }
    }
  }, []);





  const [selectedRange, setSelectedRange] = useState([]);

  useEffect(() => {
    if (selectedDay !== null) {
      const range = [];
      for (let i = 0; i <= purchaseData.duration; i++) {
        range.push(addDays(selectedDay, i));
      }
      setSelectedRange(range);
  
      updatePurchaseData("startDate", format(selectedDay, "PPP", { locale: tr }) + " 00:00");
      updatePurchaseData("finishDate", format(addDays(selectedDay, purchaseData.duration), "PPP", { locale: tr }) + " 23:59");
 
    }
  }, [selectedDay, purchaseData.duration]);
  

  function isFridayControl(date) {
    if (purchaseData.duration == 0) {
      return false;
    }
    else if (purchaseData.duration == 2) {
      return !isFriday(date);
    }
    else {
      return false;
    }

    return false;
  }

  const modifiers = {
    disabled: (date) =>
      isFridayControl(date) ||
      isBefore(date, startOfDay(new Date())) ||
      isWithinInterval(date, {
        start: selectedRange[0],
        end: selectedRange[selectedRange.length - 1],
      }),
    selectedRange: (date) =>
      isWithinInterval(date, {
        start: selectedRange[0],
        end: selectedRange[selectedRange.length - 1],
      }),
  };

  const modifiersStyles = {
    selectedRange: {
      color: "#fff",
      backgroundColor: "#F95D1F",
    },
    disabled: {
      // color: '#ccc',
      // backgroundColor: '#e4e4e4',
      pointerEvents: "none",
    },
  };


  let footer = <p>Please pick the first day.</p>;
  if (selectedDay) {
    footer = (
      <p>
        Range: {format(selectedDay, "PPP")}–
        {format(addDays(selectedDay, 6), "PPP")}
      </p>
    );
  }
  const handleDayClickAct = (day, { disabled }) => {
    if (!disabled) {
      setSelectedDay(day);
    }
  };

  const handleDayClick = (date) => {
    setSelectedDay(date);
  };

 
  const isWeekendDay = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const getDisabledDays = () => {
    let days = [];
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    for (let day = 1; day <= 31; day++) {
      let testDate = new Date(currentYear, currentMonth, day);
      if (testDate.getMonth() !== currentMonth) {
        break;
      }
      if (!isWeekendDay(testDate)) {
        days.push(testDate);
      }
    }
    return days;
  };

  const disabledDays = getDisabledDays();

  if (purchaseData.duration < 0 || purchaseData.country < 1) {
    handlePrev();
  }

 

  return (
    <div>
      <h2 className="text-color7 text-hmdx2 font-light mb-1">
        {t('baslangic_tarihi_buy')}   
      </h2>
      <p className="text-white font-light text-psm mb-5">
       {t('baslangic_tarihi_text')}
      </p>

      <div className="date-tolltake">
        <DayPicker
          mode="single"
          locale={tr}
          selected={selectedDay}
          onSelect={handleDayClickAct}
          modifiers={modifiers}
          modifiersStyles={modifiersStyles}
          disabled={modifiers.disabled}
        />
      </div>

      <div className="item-o bg-color2 radius-card p-4 mb-3">
        <div className="flex justify-between mb-4">
          <div className="text-green text-hmdxp font-light">
          {t('baslangic_tarihi')}    :
          </div>
          <div className="font-semibold text-hmdxp text-green inline-block">
            
          {purchaseData.startDate}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-green text-hmdxp r font-light">
          {t('bitis_tarihi')}   :
          </div>
          <div className="font-semibold text-hmdxp text-green inline-block">
         

          {purchaseData.finishDate}
          </div>
        </div>
      </div>

      <button  onClick={() => handleNext()} className="bg-orange py-5 rounded-md mt-4 text-white font-semibold text-hsm w-full">
       {t('devam_et')}
      </button>
    </div>
  );
}
