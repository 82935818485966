// src/contexts/CountryContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const [countryList, setcountryList] = useState([]);

  useEffect(() => {
    axios.get('https://api.tolltake.com/v1/VignetteCountry/GetList?LanguageId=1')
      .then(response => {
        const loadedCountry = response.data.Data


        setcountryList(loadedCountry);
       
      })
      .catch(error => {
        console.error('data could not be loaded', error);
      });
  }, []);

 
  

  return (
    <CountryContext.Provider value={countryList}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => useContext(CountryContext);
