import React, { useRef, useState, useEffect } from "react";
import "./dash.css";
import { Link } from "react-router-dom";
import { RiArrowRightSLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { inject, observer } from "mobx-react";

import Menu from "../../component/dashboard/Menu";

const Dash = inject("AuthStore")(
  observer(({ handlePrev, removeToken, handleNext, AuthStore, ...props }) => {
    const { t } = useTranslation();

    const BASE_URL = process.env.REACT_APP_API_URL;
    const [Vignette, setVignetteID] = useState([]);

    useEffect(() => {
      axios
        .get(`${BASE_URL}/v1/Vignette/GetCustomerVignettes`, {
          headers: {
            Authorization: `Bearer ${AuthStore.appState.token}`,
          },
        })
        .then((response) => {
          if (response.data.Success) {
            setVignetteID(response.data.Data);
            // Vignette değeri yerine response.data.Data kullanılmalı
          }
        })
        .catch((error) => {
          console.error("Veri Gönderilemedi", error);
        });
    }, []);

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    return (
      <div className="bg-dash">
        <div className="lg:hidden block top-head-page ">
          <h2 className="text-color7 lg:text-left text-center lg:text-lgheads text-hmdx2 font-light">
          <div dangerouslySetInnerHTML={{ __html: t("login_head_1") }} />
          </h2>
          <p className="text-white lg:text-left text-center lg:text-hsm text-hxss font-light lg:max-w-md mb-0">
            {t("login_text_1")}
          </p>
        </div>

        <div className="container mx-auto lg:mt-10 mt-0">
          <div className="lg:flex grid lg:gap-10 gap-0 rounded-lg lg:pt-0 pt-5 rs-mt lg:bg-colorb bg-color8">
            <Menu />
            <div className="lg:w-3/4">
              <div className="lg:bg-white  bg-color8 rounded-lg lg:px-10  px-5 py-5 ">
                <div className="flex justify-between">
                  <h2 className="text-green font-semibold mb-4  text-hsm ">
                    {t("satin_alma_gecmisi")}
                  </h2>
                  <Link
                    to="/buy-history"
                    className="text-psm lg:inline-block hidden"
                  >
                    {t("tumunu_goster")}
                  </Link>
                </div>
                <div className="lg:flex grid gap-6">
                  {Vignette &&
                    Vignette.slice(0, 3).map((items, index) => (
                      <div className="lg:w-1/3 w-full ">
                        <div className="flex items-center justify-between lg:bg-color8 bg-color10 solid-y rounded-lg p-4">
                          <div className="flex items-center gap-3 ">
                            <div>
                              <img className="w-6" src="assets/img/romn.svg" />
                            </div>
                            <div>
                              <div className="text-green font-medium text-psm">
                                {" "}
                                {items.LicensePlate}
                              </div>
                              <div className="text-green font-light text-sxss">
                                {formatDate(items.StartDate)}
                              </div>
                            </div>
                          </div>
                          <div className="text-colorsuccess text-hxsm">
                            {items.Duration} {t("gun_gecerli")}
                          </div>
                        </div>
                      </div>
                    ))}

                  {!Vignette ||
                    (Vignette.length === 0 && (
                      <div>
                        <div class="message-i">
                          {t("satin_alma_g_bulunmamaktadir")}{" "}
                          <b>
                            <Link to="/Buy"> {t("vinyet_satin_al")}</Link>
                          </b>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="flex justify-between mt-12">
                  <h2 className="text-green text-hsm font-semibold mb-4">
                    {t("araclarim")}
                  </h2>
                  <Link
                    to="/buy-history"
                    className="text-psm  lg:inline-block hidden"
                  >
                    {t("tum_araclarim")}
                  </Link>
                </div>

                {Vignette &&
                  Vignette.slice(0, 10).map((items, index) => (
                    <div className="border border-solid  border-green-900  bg-white rounded-lg  mb-4 ">
                      <Link>
                        <div className="px-5 py-3  ">
                          <div className="flex justify-between bg- items-center">
                            <div>
                              <div className="flex items-center gap-5">
                                <img src="assets/img/car_9496716.svg" />
                                <div>
                                  <h3 className="text-psm font-medium">
                                    {t("plaka")} : {items.LicensePlate}
                                  </h3>
                                  <span className="text-sxss font-light ">
                                    <img
                                      className="w-3 inline-block mr-2 border"
                                      src={items.Flag}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <RiArrowRightSLine />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}

                {!Vignette ||
                  (Vignette.length === 0 && (
                    <div>
                      <div class="message-i">
                        {t("araclariniz_b")}{" "}
                        <b>
                          <Link to="/Buy"> {t("vinyet_satin_al")}</Link>
                        </b>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })
);

export default Dash;
