import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Alert } from "reactstrap";
import { RiArrowDownSLine } from "@remixicon/react";
import { RiEyeLine } from "@remixicon/react";
import { RiEyeOffLine } from "@remixicon/react";
import { inject, observer } from "mobx-react";
import axios from "axios";
import authStore from "../../Store/AuthStore";
import { useTranslation } from "react-i18next";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
} from "../../component/Component";
const LoginPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const { t } = useTranslation();
 
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props.AuthStore != null) {
      if (props.AuthStore.isLoggedIn) {
        return props.history.push("/");
      }
    }
  });
  const onFormSubmit = (formData) => {
    setLoading(true);

    if (formData.email != "" && formData.passcode != "") {
      const postData = {
        Email: formData.email,
        Password: formData.passcode,
        IsTerm: true,
        IsContract: true,
      };
      const BASE_URL = process.env.REACT_APP_API_URL;
      axios
        .post(`${BASE_URL}/v1/Customer/Login`, postData)
        .then((response) => {
          if (response.data.Success) {
            const { DataCode: token } = response.data.Data;

            const appState = {
              isLoggedIn: true,
              token,
              user: response.data.Data,
            };
            props.AuthStore.saveToken(appState);

            setTimeout(() => {
              window.history.pushState(
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
                "login",
                `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
              );
              window.location.reload();
            }, 1000);
          } else {
            setTimeout(() => {
              setError(response.data.message);
              setLoading(false);
            }, 1000);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.Success != true) {
            setError(error.response.data.Message);
          } else {
            console.error("Veri Gönderilemedi", error);
          }
        });
    } else {
      setTimeout(() => {
        setError(t('giris_bilgileriniz_yanlis'));
        setLoading(false);
      }, 1000);
    }
  };
 
  return (
    <>
      <div className="h-screen">
        <div className="xl:flex lg:flex grid h-full">
          <div className="left-i  relative bg-color8 w-full flex items-center ">
            <div className="w-full lg:mx-24 mx-5 lg:mt-0 mt-2 ">
              <div className="lg:flex items-center mb-20 md:hidden hidden">
                <div className="w-full">
                  <Link to="/">
                    <img
                      className="w-64 relative left-[-50px] "
                      src="/assets/img/tolltake-logo.svg"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="w-full">
                  <div className="float-right">
                    <div className="font-medium lg:text-green   text-hmdxp  flex items-center gap-1">
                      <img
                        width={24}
                        className="inline-block mr-1"
                        src="/assets/img/earth.svg"
                      />
                      Turkish
                      <RiArrowDownSLine size={24} className="arrow-down-i  " />
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="text-green  lg:text-left text-center text-hmdx2   font-medium  mb-6">
              {t("kullanici_girisi")}
              </h2>

              <div className="flex gap-5  ">
                <a
                  to=""
                  className="bg-white p-2 font-semibold lg:text-psm text-sxss  w-full border border-solid border-green-900 rounded-lg flex items-center text-green"
                >
                  <img
                    className="w-30 inline-block mr-2 ml-3"
                    src="assets/img/facebook.svg"
                  />
                  {t("facebook_giris")}
                </a>

                <a
                  to=""
                  className="bg-white p-2 font-semibold lg:text-psm text-sxss  w-full border border-solid border-green-900 rounded-lg flex items-center text-green"
                >
                  <img
                    className="w-30 inline-block mr-2 ml-3"
                    src="assets/img/google.svg"
                  />
                    {t("google_giris")}
                </a>
              </div>
              <div className="text-center my-4 text-psm">{t("veya")}</div>
              {errorVal && (
                <div className="mb-3 form-auth">
                  <Alert color="danger" className="alert-icon login-alert">
                    <Icon name="alert-circle" /> {errorVal}
                  </Alert>
                </div>
              )}
              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="b-inp grid gap-2 mb-5">
                  <label className="text-green text-psm"> {t("Email")}</label>
                  <input
                    type="email"
                    id="default-01"
                    {...register("email", {
                      required:  t("bu_alan_gereklidir"),
                    })}
                    className="px-5 py-3 border border-solid border-green-900 rounded-lg"
                    placeholder="Email"
                  />
                  {errors.email && (
                    <span className="invalid">{errors.email.message}</span>
                  )}
                </div>

                <div className="b-inp grid mb-5 gap-2 relative">
                  <label className="text-green text-psm">{t("parola")}</label>

                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch absolute right-3 bottom-3.5 ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <RiEyeLine
                      name="eye "
                      className=" r-0 passcode-icon icon-show"
                    ></RiEyeLine>

                    <RiEyeOffLine
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></RiEyeOffLine>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    {...register("passcode", {
                      required:  t("bu_alan_gereklidir"),
                    })}
                    placeholder="Parolanızı giriniz"
                    className={`px-5 py-3 border border-solid border-green-900 rounded-lg ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  />
                  {errors.passcode && (
                    <span className="invalid">{errors.passcode.message}</span>
                  )}
                </div>

                <div className="b-inp">
                  <Button
                    className="shadow-md text-hsm bg-orange text-white w-full mt-5  mb-5 py-3 rounded-lg"
                    type="submit"
                  > 
                    {loading ? t('giris_yapiliyor_1') : t('giris_yap')}
                  </Button>
                </div>

                <div className="b-inp-f flex justify-between mb-8">
                  <div>
                    <label className="text-green text-sxss">
                      <input type="checkbox" /> {t("ben_hatirla")} 
                    </label>
                  </div>
                  <div>
                    <Link
                      to="/forgot"
                      className="text-green underline  text-sxss"
                    >
                       {t("sifremi_unuttum")} {}
                    </Link>
                  </div>
                </div>

                <div className="text-center text-green text-sxss lg:mb-0 mb-10">
                  <Link to="/register" className="font-light">
                     {t("uye_degilseniz")}
                    <span className="underline font-bold">{t("hemen_uye_olun")} </span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>

          <div className="right-i lg:pb-0 pb-8 relative w-full  lg:order-last order-[-1]   bg-login">
            <div className="lg:hidden px-5 mt-4 items-center  md:flex flex">
              <div className="w-full">
                <Link to="/">
                  <img
                    className="w-46 relative "
                    src="/assets/img/logo-w.svg"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="w-full">
                <div className="float-right">
                  <div className="font-medium text-white   text-sxss  flex items-center gap-1">
                    <img
                      width={24}
                      className="inline-block mr-1"
                      src="/assets/img/earth-w.svg"
                    />
                    TR
                    <RiArrowDownSLine size={24} className="arrow-down-i  " />
                  </div>
                </div>
              </div>
            </div>

            <div className="g-login h-full">
              <div className="px-16 lg:pt-16 pt-5">
                <h2
                  dangerouslySetInnerHTML={{ __html: t("login_head_1") }}
                  className="text-color7 lg:text-left text-center lg:text-hlg2 text-hmdx2 font-light"
                ></h2>

                <p className="text-white lg:text-left text-center lg:text-hmd text-psm font-light lg:max-w-md mb-0">
                  {t("login_text_1")}
                </p>
                <img className="w-80 mg-a" src="assets/img/credit.svg" />
              </div>
              <div className="relative lg:block hidden">
                <img
                  className="absolute h-full  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bf-image"
                  src="assets/img/women.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject("AuthStore")(observer(LoginPage));
