import React from "react";
import { useTranslation } from "react-i18next";

export const InvoiceForm = ({
  invoiceForm,
  setInvoiceForm,
  formData,
  handleInputChange,
  countryS,
  setIsCheckedIncoive,
  isCheckedIncoive,
  InvoiceAlert,
}) => {
  const { t } = useTranslation();

  return (
    <div className="item-o bg-color8 rounded-lg p-4 mb-3">
    <div className="flex items-center">
      <input
        id="link-checkbox"
        type="checkbox"
        value="1"
        checked={isCheckedIncoive}
        onChange={() => {
          setIsCheckedIncoive(!isCheckedIncoive);
          setInvoiceForm(!invoiceForm);
        }}
        className="relative w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      />
      <label
        htmlFor="link-checkbox"
        className="ms-2 text-gray-900 text-green text-psm font-bold relative"
      >
        {t('fatura_adresi')}
        <div className="font-light">{t('f_ihtiyacim_var')}</div>
      </label>
    </div>
    {invoiceForm && (
      <div className="form-invoice">
        <form>
          <h3> {t('kisisel_bilgiler')}</h3>
          <div className="flex gap-5 mb-4">
            <div className="w-1/2">
              <label> {t('adiniz')} (*)</label>
              <input
                name="Invoice.Name"
                value={formData.Invoice.Name}
                onChange={handleInputChange}
                placeholder={t('adinizi_giriniz')}
              />
            </div>
            <div className="w-1/2">
              <label> {t('soyadiniz')} (*)</label>
              <input
                name="Invoice.LastName"
                value={formData.Invoice.LastName}
                onChange={handleInputChange}
                placeholder={t('soyadiniz_giriniz')}
              />
            </div>
          </div>
          <div>
            <label> {t('Email')} (*)</label>
            <input
              name="Invoice.Email"
              value={formData.Invoice.Email}
              onChange={handleInputChange}
              placeholder={t('email_adresinizi_giriniz')}
            />
          </div>
          <hr />
          <h3>{t('fatura_adresi')}</h3>
          <div className="flex gap-5 mb-4">
            <div className="w-1/2">
              <label> {t('ulke')} (*)</label>
              <select
                onChange={handleInputChange}
                name="Invoice.CountryID"
                value={formData.Invoice.CountryID}
              >
                <option value="0"> {t('ulke_seciniz')} </option>
                {countryS &&
                  countryS.map((item) => (
                    <option key={item.Id} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-1/2">
              <label> {t('vilayet')} (*)</label>
              <input
                name="Invoice.TownName"
                value={formData.Invoice.TownName}
                onChange={handleInputChange}
                placeholder={t('vilayet_g')}
              />
            </div>
          </div>
          <div className="mb-4">
            <label> {t('sehir')} (*)</label>
            <input
              name="Invoice.CityName"
              value={formData.Invoice.CityName}
              onChange={handleInputChange}
              placeholder={t('sehir_g')}
            />
          </div>
          <div className="flex gap-5 mb-4">
            <div className="w-1/2">
              <label> {t('posta_kodu')} (*)</label>
              <input
                name="Invoice.Postcode"
                value={formData.Invoice.Postcode}
                onChange={handleInputChange}
                placeholder={t('posta_kodu')} 
              />
            </div>
            <div className="w-1/2">
              <label> {t('adres_text')} (*)</label>
              <input
                name="Invoice.Address"
                value={formData.Invoice.Address}
                onChange={handleInputChange}
                placeholder={t('adres_g')}
              />
            </div>
          </div>
          <div className="flex gap-5 mb-4">
            <div className="w-1/2">
              <label> {t('sirket_adi')}</label>
              <input
                name="Invoice.CompanyName"
                value={formData.Invoice.CompanyName}
                onChange={handleInputChange}
                placeholder={t('sirket_adi_g')}
              />
            </div>
            <div className="w-1/2">
              <label> {t('vergi_numarasi')}</label>
              <input
                name="Invoice.TaxNo"
                value={formData.Invoice.TaxNo}
                onChange={handleInputChange}
                placeholder={t('vergi_numarasi_g')}
              />
            </div>
          </div>
          <hr />
          <h3>{t('iletisim_fatura')}</h3>
          <div>
            <label> {t('phone_text')} (*)</label>
            <input
              name="Invoice.Phone"
              value={formData.Invoice.Phone}
              onChange={handleInputChange}
              placeholder={t('phone_g')}
            />
          </div>
        </form>

    
 
      </div>
    )}
  </div>
  )
};
