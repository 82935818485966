import React, { useState, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form, Alert } from "reactstrap";
import { RiArrowDownSLine } from "@remixicon/react";
import { useTranslation } from "react-i18next";

import { inject, observer } from "mobx-react";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
} from "../../component/Component";
import { RiCloseLargeFill } from "@remixicon/react";
const Forgot = (props) => {
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");

  const { t } = useTranslation();
  const [isMessage, setisMessage] = useState(null);

  const [pageContent, setPageContent] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onFormSubmit = (formData) => {
    if (formData.email != "") {
      const postData = {
        Email: formData.email,
      };

      axios
        .post(`${BASE_URL}/v1/Customer/ForgotPassword`, postData)
        .then((response) => {
          if (response.data.Success) {
            setisMessage(response.data.Data);
            setLoading(false);
            reset();
          } else {
            setError(response.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error.response.data.Message);

          setLoading(false);
        });
    } else {
      setTimeout(() => {
        setError(t("lutfen_eposta_giriniz"));
        setLoading(false);
      }, 1000);
    }
    setLoading(true);
  };

  return (
    <>
      <div className="h-screen">
        <div className="xl:flex lg:flex grid h-full">
          <div className="left-i  relative bg-color8 w-full flex items-center ">
            <div className="w-full lg:mx-24 mx-5 lg:mt-0 mt-2 ">
              <div className="lg:flex items-center mb-20 md:hidden hidden">
                <div className="w-full">
                  <Link to="/">
                    <img
                      className="w-64 relative left-[-50px] "
                      src="/assets/img/tolltake-logo.svg"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="w-full">
                  <div className="float-right">
                    <div className="font-medium lg:text-green   text-hmdxp  flex items-center gap-1">
                      <img
                        width={24}
                        className="inline-block mr-1"
                        src="/assets/img/earth.svg"
                      />
                      Turkish
                      <RiArrowDownSLine size={24} className="arrow-down-i  " />
                    </div>
                  </div>
                </div>
              </div>

              <h2 className="text-green  lg:text-left text-center text-hmdx2   font-medium  mb-6">
                {t("sifremi_unuttum")}
              </h2>

              {errorVal && (
                <div className="mb-3 form-auth">
                  <Alert color="danger" className="alert-icon login-alert">
                    <Icon name="alert-circle" /> {errorVal}
                  </Alert>
                </div>
              )}

              {isMessage && (
                <div className="mb-3 form-auth">
                  <Alert color="success" className="alert-icon login-alert">
                    <Icon name="alert-circle" /> {isMessage}
                  </Alert>
                </div>
              )}

              <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
                <div>
                  <div className="gap-3 mt-3 ">
                    <div className="w-full ">
                      <div className="grid relative mb-3">
                        <label className="text-green text-psm">
                          {" "}
                          {t("Email")}
                        </label>
                        <input
                          type="text"
                          id="default-01"
                          {...register("email", {
                            required: t("bu_alan_gereklidir"),
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: t("g_eposta_adresi"),
                            },
                          })}
                          className="px-5 py-3 border border-solid border-green-900 rounded-lg"
                          placeholder="Email"
                        />
                        {errors.email && (
                          <span className="invalid absolute bottom-[-19px]">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="b-inp">
                  <Button
                    className="shadow-md text-hsm bg-orange text-white w-full mt-5  mb-5 py-3 rounded-lg"
                    type="submit"
                  >
                    {loading ? t("gonderiliyor") : t("sifremi_yenile")}
                  </Button>
                </div>

                <div className="text-center text-green text-sxss lg:mb-0 mb-10">
                  <Link to="/login" className="font-light">
                    <span className="underline font-bold">
                      {t("giris_yap")}
                    </span>
                  </Link>
                </div>
              </Form>
            </div>
          </div>

          <div className="right-i lg:pb-0 pb-8 relative w-full  lg:order-last order-[-1]   bg-login">
            <div className="lg:hidden px-5 mt-4 items-center  md:flex flex">
              <div className="w-full">
                <Link to="/">
                  <img
                    className="w-46 relative "
                    src="/assets/img/logo-w.svg"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="w-full">
                <div className="float-right">
                  <div className="font-medium text-white   text-sxss  flex items-center gap-1">
                    <img
                      width={24}
                      className="inline-block mr-1"
                      src="/assets/img/earth-w.svg"
                    />
                    TR
                    <RiArrowDownSLine size={24} className="arrow-down-i  " />
                  </div>
                </div>
              </div>
            </div>

            <div className="g-login h-full">
              <div className="px-16 lg:pt-16 pt-5">
                <h2
                  dangerouslySetInnerHTML={{ __html: t("login_head_1") }}
                  className="text-color7 lg:text-left text-center lg:text-hlg2 text-hmdx2 font-light"
                ></h2>

                <p className="text-white lg:text-left text-center lg:text-hmd text-psm font-light lg:max-w-md mb-0">
                  {t("login_text_1")}
                </p>
                <img className="w-80 mg-a" src="assets/img/credit.svg" />
              </div>
              <div className="relative lg:block hidden">
                <img
                  className="absolute h-full  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bf-image"
                  src="assets/img/women.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject("AuthStore")(observer(Forgot));
