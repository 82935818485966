import React, { useRef, useState, Fragment, useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import { Stepper, Step, Button } from "@material-tailwind/react";
import { RiArrowLeftLine } from "@remixicon/react";

import { RiHome2Fill } from "@remixicon/react";

import { usePurchase } from "../../contexts/PurchaseContext";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import StepFive from "./StepFive";
import StepSix from "./StepSix";

import "react-day-picker/dist/style.css";
import { RiCloseFill } from "@remixicon/react";
import StepSeven from "./StepSeven";
import { inject, observer } from "mobx-react";


// const BuyPage = inject("BasketStore")(
//   observer(({ beforePurchaseData,handleSelectionChange BasketStore, ...props }) => {
//   const location = useLocation();
//   const [purchaseData, setPurchaseData] = useState(location.state ? location.state.state : null);
 
// const basket = BasketStore.getBasket();

// if(basket){

  
// }

function StepperBuy({ setBeforePurchaseData,beforePurchaseData, handleSelectionChange, BasketStore }) {
  const [selectedDays, setSelectedDays] = useState(2);
  const {addToCartBulk, updatePurchaseData,purchaseData } = usePurchase();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const totalSteps = 7;
  let stepChange = 0;

  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const successType = queryParams.get('successtype');
  const paymentId = queryParams.get('paymentid');
  const orderId = queryParams.get('orderid');




  // useEffect(() => {
  
  //   setIsLastStep(activeStep === totalSteps - 1);
  //   setIsFirstStep(activeStep === 0);
  // }, [activeStep, totalSteps]);

  const handleNext = () => {

    if (activeStep < totalSteps - 1) {
    
      setActiveStep((cur) => cur + 1);
    }
  };




  const handlePrev = () => {
    if (activeStep > 0) {
      setActiveStep((cur) => cur - 1);
    }
  };
  useEffect(() => {
   
if(!beforePurchaseData){
  return;
}

    if(!beforePurchaseData.country){
      return;
    }

    if(beforePurchaseData && beforePurchaseData.country){
      updatePurchaseData("country", beforePurchaseData.country);
      updatePurchaseData("countryName",beforePurchaseData.countryName);
      updatePurchaseData("countryImg", beforePurchaseData.countryImg);

      setBeforePurchaseData((prevData) => ({
        ...prevData,
        country: null,
        countryName: null,
        countryImg: null,
      }));
      stepChange  =1;
   
    }

    if(beforePurchaseData && beforePurchaseData.duration && beforePurchaseData.totalPrice && beforePurchaseData.CurrencyName&& beforePurchaseData.CurrencyId&& beforePurchaseData.vignette){
 
      updatePurchaseData("duration", beforePurchaseData.duration);
      updatePurchaseData("durationName", beforePurchaseData.durationName);

      updatePurchaseData("vehicleType", beforePurchaseData.vehicleType);
      updatePurchaseData("vehicleTypeName", beforePurchaseData.vehicleTypeName);

      updatePurchaseData("vehicleTypeImg", beforePurchaseData.vehicleTypeImg);


      updatePurchaseData("totalPrice",beforePurchaseData.totalPrice);
      updatePurchaseData("CurrencyName", beforePurchaseData.CurrencyName);
      updatePurchaseData("currency", beforePurchaseData.CurrencyId);

      updatePurchaseData("vignette", beforePurchaseData.vignette);
      
    

      setBeforePurchaseData((prevData) => ({
        ...prevData,
        durationName: null,
        vehicleType: null,
        vehicleTypeName: null,
        vehicleTypeImg: null,
      }));

      stepChange  =2;
    }

    if (beforePurchaseData && beforePurchaseData.startDate) {

      updatePurchaseData("startDate", beforePurchaseData.startDate);
      handleSelectionChange("startDate", null);
      stepChange  =2;

    }

    setActiveStep(stepChange);
  
  }, [beforePurchaseData]);


  useEffect(()=>{
   
    if(successType && paymentId && orderId){
      setActiveStep(totalSteps-2);
    }
  
   },[successType])


  return (
    <div className="">
      <div className="w-full py-4 px-3 relative">
        {activeStep !=
          6 &&(
            <div>
              <div className="flex">
                <Button
                  onClick={handlePrev}
                  className="p-0 pr-4 "
                  disabled={isFirstStep}
                >
                  <RiArrowLeftLine />
                </Button>
                <Stepper
                  activeStep={activeStep}
                  className="step-toll"
                  isLastStep={() => activeStep === totalSteps - 1}
                  isFirstStep={() => activeStep === 0}
                >
                  <Step onClick={() => setActiveStep(0)}>1</Step>
                  <Step onClick={() => setActiveStep(1)}>2</Step>
                  <Step onClick={() => setActiveStep(2)}>3</Step>
                  <Step onClick={() => setActiveStep(3)}>4</Step>
                  <Step onClick={() => setActiveStep(4)}>5</Step>
                  <Step onClick={() => setActiveStep(5)}>6</Step>
               
                </Stepper>
              </div>
              <Link to="/" className="exit-buy">
                {" "}
                <RiHome2Fill />
              </Link>
            </div>
          )}

        <div className="mt-16">
          {activeStep === 0 && (
            <StepOne
              activeStep={activeStep}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              beforePurchaseData={beforePurchaseData}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          )}
          {activeStep === 1 && (
            <StepTwo
              beforePurchaseData={beforePurchaseData}
              handleNext={handleNext}
              isLastStep={isLastStep}
              handlePrev={handlePrev}
            />
          )}
          {activeStep === 2 && (
            <StepThree
              beforePurchaseData={beforePurchaseData}
              handleNext={handleNext}
              handlePrev={handlePrev}
              isLastStep={isLastStep}
            />
          )}
          {activeStep === 3 && (
            <StepFour
              handleNext={handleNext}
              handlePrev={handlePrev}
              isLastStep={isLastStep}
            />
          )}
          {activeStep === 4 && (
            <StepFive
              handleNext={handleNext}
              handlePrev={handlePrev}
              isLastStep={isLastStep}
            />
          )}
          {activeStep === 5 && (
            <StepSix
              handleNext={handleNext}
              handlePrev={handlePrev}
              isLastStep={isLastStep}
            />
          )}
          {activeStep === 6 && (
            <StepSeven
              handleNext={handleNext}
              handlePrev={handlePrev}
              isLastStep={isLastStep}
            />
          )}
          <div className="text-center">
            <img src="assets/img/credit.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default inject('BasketStore')(observer(StepperBuy));

