import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import "./index.css";
import { getwhyService } from "../../services/whyService";
import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { RiArrowDownSLine } from "@remixicon/react";

import "swiper/css";
import "swiper/css/pagination";
import References from "../../component/homepages/References";
import Comment from "../../component/homepages/Comment";
import Faq from "../../component/homepages/Faq";

import { useTranslation } from "react-i18next";
import Loading from "../../component/loading/Loading";
import SEO from "../../component/content/Seo";

export default function Index() {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundColor = "#eff2ee";
  }, []);
  const [listWhy, setList] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const apiList = await getwhyService(19, 1);
        setList(apiList.Data);
      } catch (error) {
        console.error("API error:", error);
      }
    };

    loadData();
  }, []);

  if (listWhy) {
    return (
      
      <div className="bg-how">
               <SEO
    title={listWhy.Name}
    description={listWhy.MetaDesc}
    keywords={listWhy.MetaKey}
    author="TollTake"
    image="https://www.tolltake.com/assets/img/tolltake-logo.svg"

  />
        <div className="lg:mx-0 mx-5">
          {listWhy &&
            listWhy.Items &&
            listWhy.Items.filter((item) => item.Id === 51).map(
              (item, index) => (
                <div
                  className="prose why-o-1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item.Text),
                  }}
                />
              )
            )}
          {/* <References /> */}
          {listWhy &&
            listWhy.Items &&
            listWhy.Items.filter((item) => item.Id === 52).map(
              (item, index) => (
                <div className="container mx-auto mb-20">
                  <div className="bg-bg6 lg:p-14 p-6 rounded-lg mt-24">
                    <div className="lg:flex grid items-center w-full">
                      <div className="w-full">
                        <h2 className="text-green lg:text-hlg  text-lgheads font-semibold mb-2">
                          {item.Name}
                        </h2>
                        <p className="text-green text-hmdxp font-light max-w-80">
                          {item.Description}
                        </p>
                      </div>
                      <div className="w-full">
                        <div
                          className="text-green text-psm font-ligt about-b"
                          dangerouslySetInnerHTML={{ __html: item.Text }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>

        <Comment />

        <Faq />
      </div>
    );
  } else {
    return (
      <div>
        <Loading />
      </div>
    );
  }
}
