import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const getCountryService = async (langid) => {
  const postData = {
    languageId: langid,
  };

  try {
    const response = await axios.get(
      `${BASE_URL}/v1/VignetteCountry/GetList?LanguageId=1`,
      postData
    );
    if (response.data.Success) {
      return response.data;
    } else {
      console.error("API returned an error:", response.data.Message);
      return [];
    }
  } catch (error) {
    console.error("Veri Gönderilemedi", error);
    throw error;
  }


};
